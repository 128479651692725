<template>
  <!-- 侧边栏 -->
  <div @click.self="clickCloseLanguageBox" :class="['language-drawer','dlg-animate',{'language-drawer-active':clickLanguageBoxShow}]">
    <div :class="['box','dlg-animate']">
      <div><img @click="clickCloseLanguageBox" class="close-silde-box" src="@/assets/img/matchmaker/colse_left_box@3x.png" alt=""></div>
      <div @click="clickSelectLanguage" class="global-wrap">
        <img class="global" src="@/assets/img/matchmaker/global@3x.png" alt="global">
        <div class="box-text">{{$t("matchmaker_text6")}}</div>
      </div>
      <!-- <div @click="goTwiter" class="Twitter-wrap">
        <img class="Twitter" src="@/assets/img/matchmaker/Twitter@3x.png" alt="Twitter">
        <div class="box-text">{{$t("matchmaker_text7")}}</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clickLanguageBoxShow:false,
    }
  },
  methods:{
    clickCloseLanguageBox(){
      this.clickLanguageBoxShow = false
    },
    clickSelectLanguage(){
      this.clickCloseLanguageBox()
      // this.drawer = true
      this.$emit('clickLanguageDrawer')
    },
    // 去Twiter
    goTwiter(){
      this.clickCloseLanguageBox()
      window.open('https://twitter.com/cupidsoffice?t=rqkrIDYr37QVsOxqpmD00w&s=09','_black')
    },
  }
}
</script>

<style lang="scss" scoped>
.language-drawer{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 5;
  overflow: hidden;
  transform: translate3d(-100%, 0, 0);
  .box{
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    background: rgba(255, 255, 255, 0.95);
    height: 100%;
  }
  .close-silde-box{
    margin-top: 16px;
    margin-left: 16px;
    width: 28px;
    height: 28px;
  }
  .global-wrap{
    margin: 26px 0 22px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .Twitter-wrap{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .Twitter,.global{
    width: 28px;
    height: 28px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .box-text{
    font-size: 16px;
    font-weight: 500;
    color: #48497A;
    line-height: 22px;
  }
}
.dlg-animate{
  transition: transform 0.3s ease-in-out;
}
.language-drawer-active{
  transform: translate3d(0, 0, 0);
}
</style>