<template>
  <div class="interactive">
    <div class="interactive-box">
      <!-- <div class="language-wrap">
        <img @click="clickLanguageBox" class="language-menu" src="../assets/img/matchmaker/menu@3x.png" alt="">
      </div> -->
      <div class="content">
        <img class="content-box-common-bg-img" src="../assets/img/matchmaker/interactive_common_bg@2x.png" alt="">
        <div class="content-box">
          <img class="content-box-star-img" src="../assets/img/matchmaker/interactive_star@2x.png" alt="">
          <img class="content-box-heart-img" src="../assets/img/matchmaker/interactive_heart@2x.png" alt="">
          <div class="component-wrap">
            <!-- <transition name="el-fade-in" mode="out-in"> -->
            <transition mode="out-in" enter-active-class="animate__animated animate__fast animate__fadeInRight" leave-active-class="animate__animated animate__faster animate__fadeOutLeft">
              <component 
                v-bind:is="cut"
                keep-alive
                @handleCut="handleCut"
              ></component>
            </transition>
          </div>
        </div>
      </div>
      <LanguageBox ref="ChildLanguageBox"/>
      <Sidebar ref="ChildSider" @clickLanguageDrawer="clickLanguageDrawer" />
    </div>
  </div>
</template>

<script>
import InteractiveSure from '@/components/interactive/InteractiveSure.vue'
import InteractiveNotSure from '@/components/interactive/InteractiveNotSure.vue'
import InteractiveSingle from '@/components/interactive/InteractiveSingle.vue'
import InteractiveNotSingle from '@/components/interactive/InteractiveNotSingle.vue'
import InteractiveAgree from '@/components/interactive/InteractiveAgree.vue'
import InteractiveNotAgree from '@/components/interactive/InteractiveNotAgree.vue'
import InteractiveAgreeSuccess from '@/components/interactive/InteractiveAgreeSuccess.vue'

import LanguageBox from '@/components/LanguageBox.vue'
import Sidebar from '@/components/Sidebar.vue'
export default {
  components:{
    InteractiveSure,
    InteractiveNotSure,
    InteractiveSingle,
    InteractiveNotSingle,
    InteractiveAgree,
    InteractiveNotAgree,
    InteractiveAgreeSuccess,
    LanguageBox,
    Sidebar
  },
  data() {
    return {
      cut:'InteractiveSure',
    }
  },
  methods:{
    handleCut(value){
      this.cut = value
    },
    clickLanguageBox(){
      this.$refs.ChildSider.clickLanguageBoxShow = true
    },
    clickLanguageDrawer(){
      this.$refs.ChildLanguageBox.drawer = true
    }
  }
}
</script>

<style lang="scss" scoped>
.interactive{
  width: 100%;
  height: 100%;
  max-height: 900px;
  // display: flex;
  // justify-content: center;
  .interactive-box{
    margin: 0 auto;
    position: relative;
    background: linear-gradient(180deg, #F8F7FD 0%, #FBF1F5 100%);
    max-width: 450px;
    min-width: 360px;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .language-wrap{
    position: absolute;
    // top: 10px;
    // left: 15px;
    top: 16px;
    left: 16px;
    z-index: 4;
    .language-menu{
      width: 28px;
      height: 28px;
    }
    .language-button{
      background: rgba(255, 255, 255, 0.39);
      height: 26px;
      // width: 90px;
      // padding: 2px 11px 0 11px;
      padding: 0px 11px 0 11px;
      border-radius: 13px;
      font-size: 13px;
      line-height: 26px;
      color: #48497A;
      box-sizing: border-box;
    }
    .language-select-img{
      width: 14px;
      height: auto;
      vertical-align: middle;
      padding-bottom: 2px;
    }
  }
  .content{
    position: relative;
    width: 323px;
    height: 487px;
    background: linear-gradient(315deg, #8EC5FC 0%, #F2C2F1 100%);
    border-radius: 10px;
    .content-box{
      position: relative;
      width: 303px;
      height: 467px;
      border-radius: 4px;
      border: 1px solid rgba(255,255,255,0.4);
      margin: 10px;
      box-sizing: border-box;
      overflow: hidden;
    }
    .component-wrap{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
    .content-box-star-img{
      position: absolute;
      top: 4px;
      right: 4px;
      width: 24px;
      height: 24px;
    }
    .content-box-heart-img{
      position: absolute;
      bottom: 4px;
      left: 4px;
      width: 24px;
      height: 24px;
    }
    .content-box-common-bg-img{
      position: absolute;
      top: 20px;
      right: 0px;
      width: 131px;
      height: 351px;
      z-index: 2;
    }
  }
}
</style>