<template>
  <div class="interactive-agree-success">
    <div class="interactive-arrow-heart-wrap"><img class="interactive-arrow-heart" src="@/assets/img/matchmaker/interactive_single_fail@2x.png" alt=""></div>
    <div class="sure-text">
      {{$t("interactive_not_single_title")}}
    </div>
    <div class="sure-text-tip">
      {{$t("interactive_not_single_text")}}
    </div>
    <div class="sure-button-wrap">
      <button :class="['sure-div',{'sure-div-en':$i18n.locale === 'en'}]">
        <div @click="share('')" class="wrap">
          <img class="sure-not-icon sure-icon-margin-right" src="@/assets/img/matchmaker/interactive_share@2x.png" alt="">
          <span :class="['wrap-text',{'wrap-text-en':$i18n.locale === 'en'}]">{{$t("interactive_not_single_button")}}</span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import NativeShare from 'nativeshare'
export default {
  data() {
    return {
      nativeShare:null
    }
  },
  mounted(){
    this.shareInit()
  },
  computed:{
    shareText:function(){
      return this.$t('deliverySuccess_text8')
    }
  },
  methods:{
    shareInit(){
      this.nativeShare = new NativeShare()
      let shareData ={
        icon:'https://s3.ap-east-1.amazonaws.com/cupidsoffice.com/cupidIcon/cupidIcon%402x.png',
        title: "Cupid's Office",
        link: `${window.location.protocol}//${window.location.host}`,
        desc:"Cupid's Office"
      }
      
      this.nativeShare.setShareData(shareData)
    },
    // 测试分享
    share(command){
      // console.log(window)
      console.log('1')
      let textCopy = `${this.shareText} ${window.location.protocol}//${window.location.host}`
      var device = navigator.userAgent.toLowerCase();
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(device)) {
        //移动端
        try {
          console.log('2')
          this.nativeShare.call(command)
        } catch (err) {
          console.log('3')
          
          this.$copyText(textCopy).then(
            (e) => {
              console.log("复制成功");
              this.$message({
                message: this.$t("interactive_not_single_share_text"),
                center: true,
                type: 'success',
                customClass:'copy-success-message',
              });
            },
            (e) => {
              console.log("复制失败", e)
              this.$message({
                message: this.$t("common_copy_fail"),
                center: true,
                type: 'error',
                customClass:'copy-error-message',
              });
            }
          )
        }
      } else {
        //pc端
        this.$copyText(textCopy).then(
          (e)  => {
            console.log("复制成功");
            this.$message({
              message: this.$t("interactive_not_single_share_text"),
              center: true,
              type: 'success',
              customClass:'copy-success-message',
            });
          },
          (e) => {
            console.log("复制失败", e)
            this.$message({
              message: this.$t("common_copy_fail"),
              center: true,
              type: 'error',
              customClass:'copy-error-message',
            });
          }
        )
      }
      // navigator.share()
      //alert(navigator.share())
      //window.navigator.share('丘比特')
    },
  }
}
</script>

<style lang="scss" scoped>
.interactive-agree-success{
  position: relative;
  z-index: 5;
  padding-bottom: 18px;
  .interactive-arrow-heart-wrap{
    // margin-top: 56px;
    text-align: center;
    .interactive-arrow-heart{
      width: 220px;
      height: auto;
    }
  }
  .sure-button-wrap{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .sure-text{
    font-size: 22px;
    font-weight: 400;
    color: #1B065E;
    line-height: 30px;
    text-align: center;
    max-width: 232px;
    margin: 25px auto 13px auto;
  }
  .sure-text-tip{
    font-size: 18px;
    font-weight: 400;
    // color: #48497A;
    color: rgba(72, 73, 122, 0.6);
    line-height: 25px;
    text-align: center;
    max-width: 232px;
    margin: 0px auto 42px auto;
    // opacity: 0.6;
  }
  .sure-button{
    width: 232px;
    height: 54px;
    background: #FCF9FB;
    border-radius: 36px;
    font-size: 18px;
    font-weight: 400;
    color: #1B065E;
    line-height: 25px;
    padding: 0 30px;
  }
  .sure-div{
    width: 232px;
    background: #FCF9FB;
    border-radius: 36px;
    box-sizing: border-box;
  }
  .sure-div-en{
    width: 264px;
  }
  .wrap{
    position: relative;
    padding: 13px 0px 13px 0px;
    display: flex;
    justify-content: center;
    white-space:nowrap;
    cursor: pointer;
  }
  .wrap-text{
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #1B065E;
    position: relative;
    line-height: 28px;
    display: inline-block;
    max-width: 126px;
  }
  .wrap-text-en{
    max-width: 240px;
  }
  .sure-not-icon{
    width: 20px;
    height: 20px;
    padding: 4px 0;
  }
  .sure-button-margin-bottom{
    margin-bottom: 40px;
  }
  .sure-icon-margin-right{
    margin-right: 8px;
  }
}
</style>