<template>
  <div class="delivery">
    <div class="delivery-box">
      <div :class="['go-back',{'go-back-active':goBackActive}]">
        <img @click="goBackHome" class="go-back-img" src="../assets/img/matchmaker/go_back@2x.png" alt="">
      </div>
      <div class="content">
        <div class="img-wrap">
          <img v-if="genderShow" class="img-man-heart" src="../assets/img/matchmaker/man_heart@2x.png" alt="">
          <img v-else class="img-man-heart" src="../assets/img/matchmaker/lady_heart@2x.png" alt="">
        </div>
        <div class="text-wrap">
          <h2 :class="['text-h2','text-h2-margin',{'text-h2-en':$i18n.locale == 'en'}]">{{i18nGenderShow}}</h2>
          <p :class="['text-p',{'text-p-en':$i18n.locale == 'en'}]">{{$t("delivery_text1")}}</p>
        </div>
        <div class="form-wrap">
          <div class="form-phone-item">
            <input
              class="form-phone"
              ref="deliveryPhone" 
              @focus="phoneFoucs"
              v-model.trim="phone"
              maxlength="20"
              pattern="[0-9]*"
              type="number"
              :placeholder="$t('delivery_text2')"
              oninput="if(value.length>20)value=value.slice(0,20)" 
              onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')">
            <div v-if="areaCodeshow" @click="selectAreaCode" class="phone-area-code">+{{areaCode}}<img class="phone-area-code-img" src="../assets/img/matchmaker/clip@2x.png" alt=""></div>
          </div>
          <div class="form-code-item">
            <input 
              :class="['form-code',{'form-code-jp':$i18n.locale == 'jp'}]" 
              @focus="codeFoucs" pattern="[0-9]*" 
              type="number" 
              v-model.trim="code" 
              :placeholder="$t('delivery_text3')" 
              maxlength="4"
              @keyup.enter="submitPhone"
              oninput="if(value.length>4)value=value.slice(0,4)"
              onkeyup="value=value.replace(/[^\d]+/g,'')">
            <div :class="['form-code-button-wrap',{'form-code-button-wrap-jp':$i18n.locale == 'jp'}]">
              <button @click="getPhoneCode"  v-show="!codeShow" v-preventReClick class="form-code-get">{{$t("delivery_text4")}}</button>
              <button v-show="codeShow" class="form-code-count-dwon">{{$t("delivery_text5")}} ({{countDwonTime}}s)</button>
            </div>
          </div>
          <div class="form-button-item">
            <button 
              @click="submitPhone" 
              v-preventReClick 
              :disabled="!disabledButtonSubmit"
              :class="['form-button',{'form-button-disable':!disabledButtonSubmit}]">{{$t("common_submit")}}</button>
          </div>
        </div>
        <div class="agreement-wrap">
          <!-- <div class="agreement-text">{{$t("delivery_text6")}}</div> -->
          <div class="agreement-text">
            <!-- {{$t("delivery_text7")}} -->
            <!-- <a class="agreement-link" target="_blank" :href="provacyPolicyUrl">{{$t("delivery_text8")}}</a> -->
            <!-- <a class="agreement-link" target="_blank" :href="`${window.location.origin}/PrivacyPolicy-English.pdf`">{{$t("delivery_text8")}}</a> -->
            <!-- <a href="<%= BASE_URL %>PrivacyPolicy-English.pdf"></a> -->
            <!-- <embed src="../assets/pdf/PrivacyPolicy-English.pdf" type="application/pdf" width="100%" height="600px" /> -->
          
            <!-- <a class="agreement-link" target="_blank" :href="PrivacyPolicyPDF">{{$t("delivery_text8")}}</a> -->
            <div class="agreement-text">Submit means you agree to our</div>

            <a class="agreement-link" target="_blank" :href="`${PrivacyPolicyPDF}Terms.pdf`">Terms & Conditions</a>
            and
            <a class="agreement-link" target="_blank" :href="`${PrivacyPolicyPDF}PrivacyPolicy.pdf`">User Privacy Policy</a>
          
          </div>
        </div>
      </div>
      <transition enter-active-class="animate__animated animate__faster animate__fadeInUpBig" leave-active-class="animate__animated animate__faster animate__fadeOutDown">
        <div v-show="drawer" :class="['area-code-box']">
        <!-- <div v-show="drawer" :class="['area-code-box','animate__animated','animate__faster',drawer ? 'animate__fadeInUpBig':'animate__fadeOutLeft']"> -->
          <div class="">
            <div class="area-code-box-top">
              <img @click="closeAreaCode" class="delect" src="../assets/img/matchmaker/delect@2x.png" alt="">
              <h2 class="title">{{$t("delivery_text9")}}</h2>
              <button class="sure-button"></button>
            </div>
            <div class="area-code-box-search">
              <input @input="searchChangeInput" v-model="areaValue" maxlength="20" class="search-input" type="text" :placeholder="$t('delivery_text12')">
              <img v-if="!areaValue" class="search-img" src="../assets/img/matchmaker/search@2x.png" alt="">
              <img v-else @click="delectSearchValue" class="search-delect-img" src="../assets/img/matchmaker/delect_wrap@2x.png" alt="">
            </div>
          </div>
          <div class="country-list">
            <ul v-if="!areaValue||new_country.length !== 0" class="list-ul">
              <li @click="selectPhoneCode(item)" :class="['list-li',newAreaCodeCountry === item.code ? 'list-li-active':'']" v-for="item in new_country" :key="item.name">
                <span v-if="i18nTypeNum == 1||i18nTypeNum == 0" class="list-li-zh">{{item.zh}}<span class="check-wrap"><img v-if="newAreaCodeCountry === item.code" class="check" src="../assets/img/matchmaker/check@2x.png" alt=""></span></span>
                <span v-else-if="i18nTypeNum == 5" class="list-li-zh">{{item.jp}}<span class="check-wrap"><img v-if="newAreaCodeCountry === item.code" class="check" src="../assets/img/matchmaker/check@2x.png" alt=""></span></span>
                <span v-else class="list-li-zh">{{item.name}}<span class="check-wrap"><img v-if="newAreaCodeCountry === item.code" class="check" src="../assets/img/matchmaker/check@2x.png" alt=""></span></span>
                <span class="list-li-dial-code">+{{item.dial_code}}</span>
              </li>
            </ul>
            <div v-else class="search-none">
              {{$t("delivery_text11")}}
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// import PrivacyPolicyPDF from 'PrivacyPolicy-English.pdf';
import country from '../utils/country_hk'
import { localGet,localSet } from '@/utils/index'
import { MATCHMAKER_GENDER,MATCHMAKER_PHONE,MATCHMAKER_AREA,MATCHMAKER_SESSION_ID,MATCHMAKER_AREA_COUNTRY } from '@/utils/defaultValue'
import commonData from '@/mixin/index.js'
import { 
  codeInputNum,
  getCodeNum,
  deliveryFailNum1,
  deliveryFailNum2,
  deliveryFailNum3,
  deliveryFailNum4,
  deliverySuccessNum1,
  deliverySuccessNum2,
  deliverySuccessNum3,
  deliverySuccessNum4,
  clickSelectCountryNum,
  clickPhoneInputNum,
  clickPhoneInputSuccessNum,
  clickPhoneInputFailNum,
  clickPhoneCodeInputNum,
  clickPhoneCodeInputSuccessNum,
  clickPhoneCodeInputFailNum,
  clickSubmitNum,
  sentWaitNum,
  sentPairSuccessNum
} from '@/utils/gtagFun'
import { 
  getPhoneCodeGql,
  submitPhoneGql,
  getIpGql
} from "@/graphql/matchmaker"
export default {
  // components: {
  //   PagePDF
  // },
  mixins: [commonData],
  data() {
    return {
      PrivacyPolicyPDF: process.env.BASE_URL,
      goBackActive:false,//顶部的导航栏
      pdfurl:'https://s3.ap-east-1.amazonaws.com/cupid-test.peek.com.hk/PrivacyPolicy.pdf',
      country, // 全部国家
      new_country:country, // 搜索时出现的国家
      codeShow:false, // 倒计时状态
      areaCodeCountry:'HK', // 手机区号
      areaCode:'852', // 手机区号
      areaCodeshow:false,
      phone:'', // 手机号码
      code:'',
      verify_code_id:'', // 验证码id
      countDwonTime:60,// 倒计时
      timer:null,
      newAreaCode:'', // 区号搜索页面的区号值
      newAreaCodeCountry:'',//区号搜索页面国家
      areaValue:'', // 区号搜索框input的值
      drawer:false, // 是否展示搜索页面
      debounceCopy:null, //防抖,
      data:null //投递请求回来的数据
    }
  },  
  mounted(){
    if(!localGet(MATCHMAKER_GENDER)){
      this.$router.push('/')
    }
    this.$nextTick( ()=> {
      this.$refs.deliveryPhone.focus()
    })
    this.getIp()
    window.addEventListener('scroll',this.handleScroll)
  },
  destroyed(){
    this.closeAreaCode()
    window.removeEventListener('scroll',this.handleScroll)
  },
  computed:{
    // 判断是男女，1是男
    genderShow:function(){
      return localGet(MATCHMAKER_GENDER) === 1 ? true : false
    },
    // 判断是男女，1是男
    i18nGenderShow:function(){
      return localGet(MATCHMAKER_GENDER) === 1 ? this.$t("delivery_title_male") : this.$t("delivery_title_female")
    },
    disabledButtonSubmit:function(){
      return !!(this.phone+'') && !!this.code
    },
    provacyPolicyUrl(){
      let url = 'https://peek.com.hk/cupid_privacy_en'
      if(this.$i18n.locale === 'en'){
        url = 'https://peek.com.hk/cupid_privacy_en'
      }else if(this.$i18n.locale === 'jp'){
        url = 'https://peek.com.hk/cupid_privacy_jp'
      }else if(this.$i18n.locale === 'zhHK'){
        url = 'https://peek.com.hk/cupid_privacy_cn'
      }else{
        url = 'https://peek.com.hk/cupid_privacy_en'
      }
      return url
    }
  },
  methods:{
    // 获取ip，然后根据ip获取国家的地址
    getIp(){
      // 判断本地是否存在
      if(localGet(MATCHMAKER_AREA_COUNTRY)){
        this.areaCodeCountry = localGet(MATCHMAKER_AREA_COUNTRY)
        let code = this.country.find((value)=>{
          return value.code === this.areaCodeCountry
        })
        if(code){
          this.areaCode = code.dial_code
        }else{
          this.areaCodeCountry = 'HK'
          this.areaCode = '852'
        }
        this.areaCodeshow = true
      }
      setTimeout(()=>{
        this.areaCodeshow = true
      },2000)
      // let IP = returnCitySN["cip"]
      let IP = ''
      if(localStorage.getItem('Ip')){
        IP = localStorage.getItem('Ip')
      }
      if(!IP){
        this.areaCodeshow = true
        return
      }
      this.$apollo.query({
        query: getIpGql,
        fetchPolicy: 'no-cache',
        context: {
          headers: {
            'x-api-key': this.$HeaderKey
          }
        },
        variables: {ip:IP}
      }).then((data)=>{
        if(data.data.QueryIPLocation.err_code==0){
          if(this.areaCodeCountry !== data.data.QueryIPLocation.country_code ){
            this.areaCodeCountry = data.data.QueryIPLocation.country_code
            let code = this.country.find((value)=>{
              return value.code === this.areaCodeCountry
            })
            if(code){
              this.areaCode = code.dial_code
            }else{
              this.areaCodeCountry = 'HK'
              this.areaCode = '852'
            }
            localSet(MATCHMAKER_AREA_COUNTRY,this.areaCodeCountry)
            this.areaCodeshow = true
          }
        }
      })
    },
    handleScroll(){
      const top = document.documentElement.scrollTop //获取scroll偏移值
      console.log('1',top)
      if(top < 10){   //实际按需求取范围
        this.goBackActive = false
      }else{
        this.goBackActive = true
      }
    },
    // 投递手机号码
    submitPhone(){
      codeInputNum()
      if(this.phoneVerification()){
        clickPhoneCodeInputFailNum()
        return
      }
      clickSubmitNum()
      const query = {
        phone_country_code:parseInt(this.areaCode),
        phone:this.phone+'',
        gender:parseInt(localGet(MATCHMAKER_GENDER)),
        get_verify_code: false,
        verify_code_id:this.verify_code_id,
        verify_code:this.code,
        lag:this.i18nTypeNum,
        is_delete_phone:false,
        local_time_zone:-new Date().getTimezoneOffset()/60
      }
      this.$apollo.mutate({
        mutation: submitPhoneGql,
        context: {
          headers: {
            'x-api-key': this.$HeaderKey
          }
        },
        variables: query,
        update: (store, { data: { GameOfYueLaoDeliveryContact } }) => {
          if(GameOfYueLaoDeliveryContact.err_code === 0){
            clickPhoneCodeInputSuccessNum()
            this.handleData(GameOfYueLaoDeliveryContact)
          }else{
            deliveryFailNum1()
            if(GameOfYueLaoDeliveryContact.err_code == -10005){
              deliveryFailNum3()
            }
            if([-80001,-80002,-80003,-80004,-80005].includes(GameOfYueLaoDeliveryContact.err_code)){
              deliveryFailNum2()
              clickPhoneCodeInputFailNum()
            }
            this.$message({
              message: this.codeFail(GameOfYueLaoDeliveryContact.err_code),
              type: 'error',
              center: true,
              customClass:'copy-error-message',
            });
          }
        }
      }).catch((error) => {
        deliveryFailNum1()
        deliveryFailNum4()
        this.$message({
          message: this.tipFail.unknownReason,
          type: 'error',
          center: true,
          customClass:'copy-error-message',
        });
      })
    },
    // 投递后的数据处理
    handleData(value){
      localSet(MATCHMAKER_GENDER,value.gender)
      localSet(MATCHMAKER_PHONE,this.phone+'')
      localSet(MATCHMAKER_AREA,this.areaCode)
      localSet(MATCHMAKER_SESSION_ID,value.session_id)
      deliverySuccessNum1()
      if(value.result_type === 1||value.result_type === 2){
        deliverySuccessNum4()
      }
      if(value.result_type === 1){
        sentPairSuccessNum()
      }
      if(value.result_type === 3){
        sentWaitNum()
      }
      if([1,2,3].includes(value.result_type)){
        if(value.gender===1){
          deliverySuccessNum3()
        }else{
          deliverySuccessNum2()
        }
      }
      if(value.result_type === 2){
        this.$router.push({ name: 'matter'})
      }else{
        this.$router.push({ name: 'deliverySuccess'})
      }
    },
    // 手机输入框聚焦
    phoneFoucs(){
      clickPhoneInputNum()
    },
    // 验证码输入框聚焦
    codeFoucs(){
      clickPhoneCodeInputNum()
    },
    // 获取手机验证码
    getPhoneCode(){
      if(this.phoneCodeVerification()){
        clickPhoneInputFailNum()
        return
      }
      if(this.areaCode === '86'){
        this.$message({
          message: this.$t("delivery_text13"),
          center: true,
          customClass:'copy-error-message',
          type: 'warning'
        });
        return
      }
      getCodeNum()
      //倒计时
      this.codeShow = true;
      this.timer = setInterval(this.getValidStr, 1000)
      //提交的数据
      const data = {      //传递参数
        phone_country_code:parseInt(this.areaCode),
        phone:this.phone+'',
        gender:parseInt(localGet(MATCHMAKER_GENDER)),
        get_verify_code: true,
        lag:this.i18nTypeNum,
        is_delete_phone:false
      }
      // *************
      this.$apollo.mutate({
        mutation: getPhoneCodeGql,
        context: {
          headers: {
            'x-api-key': this.$HeaderKey
          }
        },
        variables: data,
        update: (store, { data: { GameOfYueLaoDeliveryContact } }) => {
          if(GameOfYueLaoDeliveryContact.err_code === 0){
            this.$message({
              message: this.$t("delivery_text15"),
              type: 'success',
              center: true,
              customClass:'copy-error-message',
            });
            this.verify_code_id = GameOfYueLaoDeliveryContact.verify_code_id
            clickPhoneInputSuccessNum()
          }else{
            this.$message({
              message: this.codeFail(GameOfYueLaoDeliveryContact.err_code),
              center: true,
              customClass:'copy-error-message',
              type: 'error'
            });
            this.codeShow = false
            clearInterval(this.timer);
            this.countDwonTime = 60;
            clickPhoneInputFailNum()
          }
        }
      }).catch((error) => {
        this.codeShow = false
        clearInterval(this.timer);
        this.countDwonTime = 60;
        this.$message({
          message: this.tipFail.unknownReason,
          center: true,
          customClass:'copy-error-message',
          type: 'error'
        });
      })
    },
    //验证码倒计时
    getValidStr(){
      if (this.countDwonTime > 0 && this.countDwonTime <= 60) {
        this.countDwonTime--;
        if (this.countDwonTime === 0) {
          clearInterval(this.timer);
          this.countDwonTime = 60;
          this.timer = null;
          this.codeShow = false;
        }
      }
    },
    //phone投递验证
    phoneVerification(){
      // let reg = new RegExp("^[0-9]*$"); //正则表达式
      const reg = /^\d{2,16}$/
      const regCode = /^\d{1,4}$/
      if(!this.verify_code_id){
        this.$message({
          message: this.$t("delivery_text14"),
          center: true,
          customClass:'copy-error-message',
          type: 'error'
        });
        return true
      }else if(!reg.test(this.phone+'')){
        this.$message({
          message: this.$t("codoFail2"),
          center: true,
          customClass:'copy-error-message',
          type: 'error'
        });
        return true
      }else if(!regCode.test(this.code)){
        this.$message({
          message: this.$t("codoFail7"),
          center: true,
          customClass:'copy-error-message',
          type: 'error'
        });
        return true
      }else{
        return false
      }
     
    },
    //phone验证码验证
    phoneCodeVerification(){
      // const reg = new RegExp("^\d{1,12}$") //正则表达式
      const reg = /^\d{2,16}$/
      if(!reg.test(this.phone+'')){
        this.$message({
          message: this.$t("codoFail2"),
          center: true,
          customClass:'copy-error-message',
          type: 'error'
        });
        return true
      }
      return false
    },
    // 点击打开区号选择
    selectAreaCode(){
      this.drawer = true
      this.newAreaCode = this.areaCode
      this.newAreaCodeCountry = this.areaCodeCountry
    },
    // 关闭区号选择
    closeAreaCode(){
      this.drawer = false
      this.new_country = this.country
      this.newAreaCode = ''
      this.newAreaCodeCountry = ''
      this.areaValue = ''
    },
    // 选中区号
    selectPhoneCode(item){
      // this.newAreaCode = item.dial_code
      // this.newAreaCodeCountry = item.code
      if(item.dial_code == '86'){
        this.$message({
          message: this.$t("delivery_text13"),
          center: true,
          customClass:'copy-error-message',
          type: 'warning'
        });
      }else{
        this.areaCode = item.dial_code
        this.areaCodeCountry = item.code
      }
      this.closeAreaCode()
      clickSelectCountryNum()
    },
    // 确定选中的区号
    sureAreaCode(){
      this.areaCode = this.newAreaCode
      this.areaCodeCountry = this.newAreaCodeCountry
      this.closeAreaCode()
    },
    // 删除搜索框的内容
    delectSearchValue(){
      this.areaValue = ''
      this.new_country = this.country
    },
    //处理手机搜索的函数
    searchChangeInput(e){
      if(this.debounceCopy){
        clearTimeout(this.debounceCopy)
      }
      this.debounceCopy = setTimeout(() => {
        if(e.target.value){
          this.new_country = this.country.filter(item=>{
            if(this.i18nTypeNum == 1||this.i18nTypeNum == 0){
              return item.zh.toLowerCase().indexOf(e.target.value.toLowerCase()) != -1 || item.dial_code.toLowerCase().indexOf(e.target.value.toLowerCase())!= -1
            }else if(this.i18nTypeNum == 5){
              return item.jp.toLowerCase().indexOf(e.target.value.toLowerCase()) != -1 || item.dial_code.toLowerCase().indexOf(e.target.value.toLowerCase())!= -1
            }else{
              return item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) != -1 || item.dial_code.toLowerCase().indexOf(e.target.value.toLowerCase())!= -1
            }
          }) 
        }else{
          this.new_country = this.country
        } 
      }, 500)
    },
    goBackHome(){
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss">
.delivery{
  width: 100%;
  height: 100%;
  max-height: 900px;
  // display: flex;
  // justify-content: center;
  .delivery-box{
    margin: 0 auto;
    position: relative;
    background: linear-gradient(180deg, #E0D8F7 0%, #ECBACC 100%);
    max-width: 450px;
    min-width: 360px;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    overflow: hidden;
  }
  .go-back{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    background: transparent;
    transition: 0.5s;
    z-index: 6;
    .go-back-img{
      width: 24px;
      height: auto;
      position: absolute;
      top: 50%;
      left: 22px;
      transform: translateY(-50%);
    }
  }
  .go-back-active{
    position: fixed;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
  }
  .content{
    margin: 20px 0;
  }
  .img-wrap{
    text-align: center;
    .img-man-heart{
      width: 80px;
      height: auto;
    }
  }
  .text-wrap{
    text-align: center;
    max-width: 320px;
    margin: 0 auto;
    .text-h2{
      font-size: 28px;
      line-height: 40px;
      color: #48497A;
      letter-spacing: 2px;
      padding-left: 2px;
    }
    .text-h2-en{
      letter-spacing: 0;
      padding-left: 0;
    }
    .text-h2-margin{
      margin: 19px 0 12px 0;
    }
    .text-p{
      font-size: 17px;
      line-height: 24px;
      padding: 0 18px 0 17px;
      color: #48497A;
      letter-spacing: 2px;
      // padding-left: 2px;
    }
    .text-p-en{
      letter-spacing: 0;
      padding: 0 17px 0 17px;
    }
  }
  .form-wrap{
    width: 285px;
    margin: 0 auto;
    .form-phone-item{
      position: relative;
      text-align: center;
      margin: 27px 0 24px 0;
      .form-phone{
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 0.3);
        width: 285px;
        height: 50px;
        border-radius: 25px;
        border: none;
        padding-left: 96px;
        padding-right: 24px;
        font-size: 16px;
        line-height: 24px;
        color: #48497A;
        text-align: left;
      }
      .phone-area-code{
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        font-size: 16px;
        line-height: 50px;
        color: #48497A;
        cursor: pointer;
        width: 90px;
        height: 48px;
        padding-left: 4px;
      }
      .phone-area-code-img{
        vertical-align: middle;
        padding-bottom: 2px;
        padding-left: 8px;
        width: 11px;
        height: auto;
      }
    }
    .form-code-item{
      position: relative;
      text-align: center;
      .form-code{
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 0.3);
        width: 285px;
        height: 50px;
        border-radius: 25px;
        border: none;
        padding: 0 24px;
        font-size: 16px;
        line-height: 24px;
        color: #48497A;
        text-align: left;
      }
      .form-code-jp{
        padding: 0 16px;
      }
      .form-code-button-wrap{
        position: absolute;
        top: 50%;
        right: 22px;
        transform: translateY(-50%);
      }
      .form-code-button-wrap-jp{
        right: 16px;
      }
      .form-code-get{
        background-color: transparent;
        font-size: 16px;
        line-height: 24px;
        color: #48497A;
        text-align: right;
        padding: 0;
      }
      .form-code-count-dwon{
        background-color: transparent;
        font-size: 16px;
        line-height: 24px;
        color: rgba(72, 73, 122, 0.4);
        text-align: right;
        padding: 0;
      }
    }
    .form-button-item{
      position: relative;
      text-align: center;
      margin-top: 24px;
      .form-button{
        box-sizing: border-box;
        background-color: #6567AE;
        width: 285px;
        height: 50px;
        border-radius: 25px;
        border: none;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        font-weight: bold;
        cursor: pointer;
      }
      .form-button-disable{
        opacity: 0.5;
      }
    }
    input::-webkit-input-placeholder{
      color:rgba(72, 73, 122, 0.4);
      font-size: 16px;
      line-height: 24px;
    }
    input::-moz-placeholder{   /* Mozilla Firefox 19+ */
      color:rgba(72, 73, 122, 0.4);
      font-size: 16px;
      line-height: 24px;
    }
    input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
      color:rgba(72, 73, 122, 0.4);
      font-size: 16px;
      line-height: 24px;
    }
    input:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
      color:rgba(72, 73, 122, 0.4);
      font-size: 16px;
      line-height: 24px;
    }
  }
  .agreement-wrap{
    text-align: center;
    margin-top: 24px;
    margin:  24px auto 0;
    max-width: 286px;
    .agreement-text{
      font-size: 12px;
      line-height: 17px;
      color: #706C95;
    }
    .agreement-link{
      font-size: 12px;
      line-height: 17px;
      color: #906ADF;
      cursor: pointer;
    }
  }
  .area-code-box{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 7;
    .area-code-box-top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      .delect{
        width: 24px;
        height: auto;
        cursor: pointer;
      }
      .title{
        font-size: 17px;
        line-height: 24px;
        color: #48497A;
      }
      .sure-button{
        font-size: 17px;
        line-height: 24px;
        width: 24px;
        color: #906ADF;
        background-color: transparent;
        cursor: pointer;
        // margin: 0;
        padding: 0 2px 0 0;
      }
    }
    .area-code-box-search{
      position: relative;
      text-align: center;
      margin:0 30px;
      .search-input{
        width: 100%;
        height: 44px;
        border-radius: 8px;
        background: rgba(236, 219, 237, 0.3);
        border: none;
        box-sizing: border-box;
        padding: 0 20px;
      }
      .search-delect-img,.search-img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        width: 24px;
        height: 24px;
      }
      input::-webkit-input-placeholder{
        color:rgba(112, 108, 149, 0.3);
        font-size: 16px;
        line-height: 22px;
      }
      input::-moz-placeholder{   /* Mozilla Firefox 19+ */
        color:rgba(112, 108, 149, 0.3);
        font-size: 16px;
        line-height: 22px;
      }
      input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
        color:rgba(112, 108, 149, 0.3);
        font-size: 16px;
        line-height: 22px;
      }
      input:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
        color:rgba(112, 108, 149, 0.3);
        font-size: 16px;
        line-height: 22px;
      }
    }
    .country-list{
      flex-grow: 1;
      overflow-y: scroll;
      margin: 20px 0;
      .list-li{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        line-height: 22px;
        color: #706C95;
        padding: 17px 0px;
        margin: 0 30px;
        // border-top: 1px solid #D8D8E1;
        border-bottom: 1px solid rgba(216, 216, 225, 0.3);
        box-sizing: border-box;
      }
      .list-li-active{
        color: #906ADF;
      }
      .list-li-zh{
        position: relative;
      }
      .check-wrap{
        position: relative;
      }
      .check{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -30px;
        width: 24px;
        height: auto;

      }
    }
    .search-none{
      font-size: 16px;
      line-height: 24px;
      margin-top: 120px;
      text-align: center;
      color: #B8B8B8;
    }
  }
  // .PDF-page{
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   left: 0;
  //   top: 0;
  // }
}
</style>