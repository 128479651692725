<template>
  <div class="matchmaker">
    <div v-show="matchmakerInteractiveShow" class="matchmaker-box">
      <!-- <div v-show="enterStatus" class="language-wrap">
        <img @click="clickLanguageBox" class="language-menu" src="../assets/img/matchmaker/menu@3x.png" alt="">
      </div> -->
      <!-- <div @click="share()" style="position: absolute;top: 16px;right: 16px;">
        分享
      </div> -->
      <div v-show="enterStatus" class="content">
        <!-- 箭头 -->
        <!-- <div class="arrow-wrap">
          <div class="arrow-img-box">
            <img class="arrow-img" src="../assets/img/matchmaker/arrow@2x.png" alt="">
            <div :class="['arrow-words',{'arrow-words-en':$i18n.locale == 'en'}]">
              <h2 class="arrow-title">{{$t("matchmaker_title")}}</h2>
              <p class="arrow-text">{{$t("matchmaker_text1")}}</p>
              <p class="arrow-text">{{$t("matchmaker_text2")}}</p>
            </div>
          </div>
        </div> -->
        <!-- 箭头 -->
        <!-- <div class="arrow-wrap">
          <div class="arrow-img-box">
            <img class="arrow-img" src="../assets/img/matchmaker/arrow@2x.png" alt="">
            <div :class="['arrow-words',{'arrow-words-en':$i18n.locale == 'en'}]">
              <h2 class="arrow-title">{{$t("matchmaker_title")}}</h2>
              <p class="arrow-text">{{$t("matchmaker_text1")}}</p>
              <p class="arrow-text">{{$t("matchmaker_text2")}}</p>
            </div>
          </div>
        </div> -->
        <div class="arrow-new-wrap">
          <div :class="['arrow-new-box',{'arrow-new-box-en':$i18n.locale == 'en'},{'arrow-new-box-jp':$i18n.locale == 'jp'}]">
            <img class="arrow-new-end" src="../assets/img/matchmaker/arrow_end@2x.png" alt="">
            <img class="arrow-new-head" src="../assets/img/matchmaker/arrow_head@2x.png" alt="">
            <div :class="['arrow-new-words',{'arrow-new-words-en':$i18n.locale == 'en'},{'arrow-new-words-jp':$i18n.locale == 'jp'}]">
              <h2 :class="['arrow-new-title',{'arrow-new-title-en':$i18n.locale == 'en'}]">{{$t("matchmaker_title")}}</h2>
              <p :class="['arrow-new-text',{'arrow-new-text-en':$i18n.locale == 'en'}]">{{$t("matchmaker_text1")}}</p>
              <p :class="['arrow-new-text',{'arrow-new-text-en':$i18n.locale == 'en'}]">{{$t("matchmaker_text2")}}</p>
              <!-- <p @click="handleVideo" :class="['arrow-new-video',{'arrow-new-video-jp':$i18n.locale == 'jp'}]">
                <img class="arrow-new-video-icon" src="../assets/img/matchmaker/video_icon@2x.png" alt="">{{$t("play_instructions")}}
              </p> -->
            </div>
          </div>
        </div>
        <!-- 天使 -->
        <div class="angel-wrap">
          <div class="angel-img-box">
            <img class="angel-img" src="../assets/img/matchmaker/angel@2x.png" alt="">
            <div @click="selectMan" v-preventReClick class="angel-man">
              <img class="angel-man-img" src="../assets/img/matchmaker/man@2x.png" alt="">
              <span :class="['angel-gender',{'angel-gender-en':$i18n.locale == 'en'}]">{{$t("common_man")}}</span>
              <div v-show="angelManMask" class="angel-man-mask"></div>
            </div>
            <div @click="selectLady" class="angel-lady">
              <img class="angel-lady-img" src="../assets/img/matchmaker/lady@2x.png" alt="">
              <span :class="['angel-gender',{'angel-gender-en':$i18n.locale == 'en'}]">{{$t("common_lady")}}</span>
              <div v-show="angelLadyMask" class="angel-lady-mask"></div>
            </div>
            <div class="tip-wrap">
              <div class="tip-text1">{{$t("matchmaker_text3")}}</div>
              <div class="tip-text2"><span class="tip-line">&nbsp;</span>{{$t("matchmaker_text4")}}</div>
            </div>
            <!-- <div class="tip-wrap">
              <div class="tip-text1">{{$t("matchmaker_text3")}}</div>
              <div class="tip-text2"><span class="tip-line">&nbsp;</span>{{$t("matchmaker_text4")}}</div>
            </div> -->
          </div>
        </div>
        <!-- 官网 -->
        <!-- <div class="banner-wrap">
          <img v-if="$i18n.locale == 'en'" @click="goToPeekWeb"  class="banner-img" src="../assets/img/matchmaker/banner_en@2x.png" alt="">
          <img v-else-if="$i18n.locale == 'jp'" @click="goToPeekWeb"  class="banner-img" src="../assets/img/matchmaker/banner_jp@2x.png" alt="">
          <img v-else @click="goToPeekWeb"  class="banner-img" src="../assets/img/matchmaker/banner@2x.png" alt="">
        </div> -->
        <!-- <div class="banner-wrap">
          <div class="banner-text">We built this website just for kicks.</div>
          <div>
            <img @click="sendEmail" class="banner-icon" src="../assets/img/matchmaker/vector@2x.png" alt="">
            <img @click="goWhatsApp" class="banner-icon" src="../assets/img/matchmaker/phone@2x.png" alt="">
          </div>
        </div> -->
      </div>
      <div class="content-bottom">
        <div class="banner-text">We built this website just for kicks.</div>
          <div>
            <img @click="goBlog" class="banner-icon" src="../assets/img/matchmaker/blog.png" alt="">
            <img @click="sendEmail" class="banner-icon" src="../assets/img/matchmaker/vector@2x.png" alt="">
            <img @click="goWhatsApp" class="banner-icon" src="../assets/img/matchmaker/phone@2x.png" alt="">
        </div>
      </div>
      <!-- 语言选择 -->
      <transition enter-active-class="animate__animated animate__faster animate__fadeInLeft" leave-active-class="animate__animated animate__faster animate__fadeOutLeft">
        <div v-show="drawer" :class="['area-code-box']">
        <!-- <div v-show="drawer" :class="['area-code-box','animate__animated','animate__faster',drawer ? 'animate__fadeInUpBig':'animate__fadeOutLeft']"> -->
          <div class="">
            <div class="area-code-box-top">
              <img @click="closeLanguageShow" class="delect" src="../assets/img/matchmaker/delect@2x.png" alt="">
              <h2 class="title">{{$t("matchmaker_text5")}}</h2>
              <button class="sure-button"></button>
            </div>
          </div>
          <div class="country-list">
            <ul class="list-ul">
              <li @click="selectLanguage(item.value,item.label)" :class="['list-li',languageValue === item.value ? 'list-li-active':'']" v-for="item in languageOption" :key="item.value">
                <span class="list-li-zh">{{item.label}}<img v-if="languageValue === item.value" class="check" src="../assets/img/matchmaker/check@2x.png" alt=""></span>
                <!-- <span class="list-li-dial-code"></span> -->
              </li>
            </ul>
          </div>
        </div>
      </transition>
      <!-- 侧边栏 -->
      <div @click.self="clickCloseLanguageBox" :class="['language-drawer','dlg-animate',{'language-drawer-active':clickLanguageBoxShow}]">
        <div :class="['box','dlg-animate']">
          <div><img @click="clickCloseLanguageBox" class="close-silde-box" src="../assets/img/matchmaker/colse_left_box@3x.png" alt=""></div>
          <div @click="clickSelectLanguage" class="global-wrap">
            <img class="global" src="../assets/img/matchmaker/global@3x.png" alt="global">
            <div class="box-text">{{$t("matchmaker_text6")}}</div>
          </div>
          <!-- <div @click="goTwiter" class="Twitter-wrap">
            <img class="Twitter" src="../assets/img/matchmaker/Twitter@3x.png" alt="Twitter">
            <div class="box-text">{{$t("matchmaker_text7")}}</div>
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="videoShow" class="video-box" >
      <video :src='videoSrc' 
        controls='controls' 
        webkit-playsinline="true"
        playsinline="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true" class="video-src" autoplay />
      <img @click="closeVideo" class="video-delect" src="../assets/img/matchmaker/close1.png" alt="">
    </div>
    
  </div>
</template>

<script>
// import NativeShare from 'nativeshare'
import { localSet, localGet ,localDelect} from '@/utils/index'
import { adClickNum1,adClickNum2,invitationOtherNum,selectEN,selectJP,selectHK,clickSelectMan,clickSelectLady } from '@/utils/gtagFun'
import { MATCHMAKER_GENDER,MATCHMAKER_PHONE,MATCHMAKER_AREA,MATCHMAKER_SESSION_ID,MATCHMAKER_LANGUAGE,MATCHMAKER_AREA_COUNTRY } from '@/utils/defaultValue'
import commonData from '@/mixin/index.js'
import { 
  getPhoneInfGql,
  getIpGql
} from "@/graphql/matchmaker"
export default {
  // beforeRouteEnter:(to, from, next)=>{
  //   if(from.name === 'interactive'){
      
  //     next(vm=>{
  //       vm.matchmakerInteractiveShow = true
  //     })
  //   }else{
  //     if(localGet(MATCHMAKER_SESSION_ID)){
  //       next(vm=>{
  //         vm.matchmakerInteractiveShow = true
  //       })
  //     }else{
  //       // next()
  //       next(vm=>{
  //         vm.matchmakerInteractiveShow = false
  //         vm.$router.replace('interactive')
  //       })
  //     }
  //   }
  // },
  mixins: [commonData],
  data() {
    return {
      matchmakerInteractiveShow:true,//interactive进来的状态
      enterStatus:false,//进来的状态
      angelManMask:false,
      angelLadyMask:false,
      debounceSelectMan:null,
      debounceSelectLady:null,
      drawer:false,
      languageValue:'en',
      languageLocale:'English',
      videoShow:false,
      clickLanguageBoxShow:false,
      nativeShare:null
    }
  },
  mounted(){
    setTimeout(()=>{
      this.enterStatus = true
    },6000)
    if(localGet(MATCHMAKER_SESSION_ID)){
      this.getUserInf()
    }else{
      this.enterStatus = true
    }
    this.googleEnterOtherNum()
    // this.languageValue = localGet(MATCHMAKER_LANGUAGE)
    // this.languageLocale = this.getLanguageLocale(this.languageValue)[0].label || '繁體中文'
    this.getIp()
    // this.shareInit()
  },
  computed:{
    languageOption:function(){
      return [
        // {value:'zhHK',label:'繁體中文'},
        {value:'en',label:'English'},
        // {value:'jp',label:'日本語'}
      ]
    },
    videoSrc:function(){
      let url = 'https://s3.ap-east-1.amazonaws.com/cupid.peek.com.hk/video/cupidVideo.mp4'
      if(this.languageValue === 'zhHK'){
        url = 'https://s3.ap-east-1.amazonaws.com/cupid.peek.com.hk/video/cupidVideo.mp4'
      }else if(this.languageValue === 'en'){
        url = 'https://s3.ap-east-1.amazonaws.com/cupid.peek.com.hk/video/cupidVideoEN.mp4'
      }else if(this.languageValue === 'zhTW'){
        url = 'https://s3.ap-east-1.amazonaws.com/cupid.peek.com.hk/video/cupidVideo.mp4'
      }else if(this.languageValue === 'zhCN'){
        url = 'https://s3.ap-east-1.amazonaws.com/cupid.peek.com.hk/video/cupidVideo.mp4'
      }else if(this.languageValue === 'jp'){
        url = 'https://s3.ap-east-1.amazonaws.com/cupid.peek.com.hk/video/cupidVideoJP.mp4'
      }else{
        url = 'https://s3.ap-east-1.amazonaws.com/cupid.peek.com.hk/video/cupidVideoEN.mp4'
      }
      return  url
    }
  },
  methods:{
    // 获取ip，然后根据ip获取国家的地址
    getIp(){
      // 判断本地是否存在
      if(localGet(MATCHMAKER_AREA_COUNTRY)){
        return
      }
      // let IP = returnCitySN["cip"]
      // console.log('returnCitySN',returnCitySN)
      let IP = ''
      if(localStorage.getItem('Ip')){
        IP = localStorage.getItem('Ip')
      }
      if(!IP){
        return
      }
      this.$apollo.query({
        query: getIpGql,
        fetchPolicy: 'no-cache',
        context: {
          headers: {
            'x-api-key': this.$HeaderKey
          }
        },
        variables: {ip:IP}
      }).then((data)=>{
        if(data.data.QueryIPLocation.err_code==0){
          localSet(MATCHMAKER_AREA_COUNTRY,data.data.QueryIPLocation.country_code)
        }
      })
    },
    // shareInit(){
    //   this.nativeShare = new NativeShare()
    //   let shareData ={
    //     title: 'NativeShare',
    //   }
    //   this.nativeShare.setShareData(shareData)
    // },
    // // 测试分享
    // share(command){
    //   // console.log(window)
    //   try {
    //       this.nativeShare.call(command)
    //   } catch (err) {
    //       // 如果不支持，你可以在这里做降级处理
    //     console.log('22222')
    //   }
    //   // navigator.share()
    //   //alert(navigator.share())
    //   //window.navigator.share('丘比特')
    // },
    selectLanguage(value,label){
      this.$i18n.locale = value
      this.languageValue = value
      this.languageLocale = label
      localSet(MATCHMAKER_LANGUAGE,value)
      this.closeLanguageShow()
      if(value == 'zhHK'){
        selectHK()
      }else if(value == 'en'){
        selectEN()
      }else if(value == 'jp'){
        selectJP()
      }
    },
    clickSelectLanguage(){
      this.clickCloseLanguageBox()
      this.drawer = true
    },
    closeLanguageShow(){
      this.drawer = false
    },
    getLanguageLocale(value){
      let data = this.languageOption.filter(function(item){
        return item.value === value; 
      })
      let item =  data || [{value:'zhHK',label:'繁體中文'}]
      return item
    },
    // 获取用户信息
    getUserInf(){
      const query = {
        session_id:localGet(MATCHMAKER_SESSION_ID),
        get_verify_code:false,
        gender:parseInt(localGet(MATCHMAKER_GENDER)),
        phone_country_code:parseInt(localGet(MATCHMAKER_AREA)),
        phone:localGet(MATCHMAKER_PHONE),
        is_delete_phone:false
        // lag:this.i18nTypeNum
      }
      this.$apollo.mutate({
        mutation: getPhoneInfGql,
        context: {
          headers: {
            'x-api-key': this.$HeaderKey
          }
        },
        variables: query,
        update: (store, { data: { GameOfYueLaoDeliveryContact } }) => {
          if(GameOfYueLaoDeliveryContact.err_code === 0){
            this.handleData(GameOfYueLaoDeliveryContact)
          }else{
            this.$message({
              message: this.codeFail(GameOfYueLaoDeliveryContact.err_code),
              type: 'error',
              center: true,
              customClass:'copy-error-message',
            });
          }
        }
      }).catch((error) => {
        this.$message({
          message: this.tipFail.unknownReason,
          type: 'error',
          center: true,
          customClass:'copy-error-message',
        });
      })
    },
    // 处理数据
    handleData(value){
      if(value.result_type === 3){
        this.$router.push({ name: 'deliverySuccess'})
      }else if(value.result_type === 1||value.result_type === 2){
        this.$router.push({ name: 'matter'})
      }else{
        this.enterStatus = true
        localDelect(MATCHMAKER_GENDER)
        localDelect(MATCHMAKER_PHONE)
        localDelect(MATCHMAKER_AREA)
        localDelect(MATCHMAKER_SESSION_ID)
      }
    },
    selectMan(){
      if(this.angelLadyMask){
        return
      }
      if(this.debounceSelectMan){
        clearTimeout(this.debounceSelectMan)
      }
      this.angelManMask = true
      this.debounceSelectMan = setTimeout(()=>{
        this.angelManMask = false
        localSet(MATCHMAKER_GENDER,1)
        clickSelectMan()
        // this.$router.push({ path: `/delivery/${value.user_id}` })
        this.$router.push({ path: 'delivery' })
      },500)
    },
    // 选择女士
    selectLady(){
      if(this.angelManMask){
        return
      }
      if(this.debounceSelectLady){
        clearTimeout(this.debounceSelectLady)
      }
      this.angelLadyMask = true
      this.debounceSelectLady = setTimeout(()=>{
        this.angelLadyMask = false
        localSet(MATCHMAKER_GENDER,2)
        clickSelectLady()
        this.$router.push({ path: 'delivery' })
      },500)
    },
    // 打开视频
    handleVideo(){
      this.videoShow = true
    },
    closeVideo(){
      this.videoShow = false
    },
    //打开侧边的栏
    clickLanguageBox(){
      this.clickLanguageBoxShow = true
    },
    clickCloseLanguageBox(){
      this.clickLanguageBoxShow = false
    },
    // 去Twiter
    goTwiter(){
      this.clickCloseLanguageBox()
      window.open('https://twitter.com/cupidsoffice?t=rqkrIDYr37QVsOxqpmD00w&s=09','_black')
      
    },
    // 去官网
    goToPeekWeb(){
      adClickNum1()
      adClickNum2()
      window.open('https://peek.com.hk/')
    },
    goWhatsApp(){
      
      window.open('https://wa.me/85255109492')
    },
    sendEmail(){
      window.location.href = "mailto:joechen@hey.com";
    },
    goBlog(){
      window.open('https://world.hey.com/joechen/cupid-s-office-shortcut-to-making-a-connection-953184ef')
    },
    // 处理谷歌他人分享的链接
    googleEnterOtherNum(){
      if(this.$route.query.data){
        invitationOtherNum()
      }
    }
  }
}
</script>
<style lang="scss">
.matchmaker{
  width: 100%;
  height: 100%;
  max-height: 900px;
  // display: flex;
  // justify-content: center;
  .matchmaker-box{
    margin: 0 auto;
    position: relative;
    background: linear-gradient(180deg, #E0D8F7 0%, #ECBACC 100%);
    max-width: 450px;
    min-width: 360px;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .language-wrap{
    position: absolute;
    // top: 10px;
    // left: 15px;
    top: 16px;
    left: 16px;
    z-index: 4;
    .language-menu{
      width: 28px;
      height: 28px;
    }
    .language-button{
      background: rgba(255, 255, 255, 0.39);
      height: 26px;
      // width: 90px;
      // padding: 2px 11px 0 11px;
      padding: 0px 11px 0 11px;
      border-radius: 13px;
      font-size: 13px;
      line-height: 26px;
      color: #48497A;
      box-sizing: border-box;
    }
    .language-select-img{
      width: 14px;
      height: auto;
      vertical-align: middle;
      padding-bottom: 2px;
    }
  }
  .content{
    margin: 0px 0 90px 0;
  }
  .content-bottom{
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    .banner-text{
      font-size: 12px;
      color: #57578F;
      margin-bottom: 10px;
    }
    .banner-icon{
      width: 26px;
      height: 26px;
      margin: 0 16px;
      cursor: pointer;
    }
  }
  .arrow-wrap{
    display: flex;
    justify-content: center;
    .arrow-img-box{
      position: relative;
      margin-left: 20px;
    }
    .arrow-img{
      width: 290px;
      height: auto;
    }
    .arrow-words{
      position: absolute;
      top: 40%;
      left: 46%;
      transform: translate(-50%,-50%);
      width: 200px;
      text-align: center;
      .arrow-title{
        letter-spacing: 2px;
        font-size: 30px;
        line-height: 42px;
        padding-left: 2px;
        color: #48497A;
        margin-bottom: 5px;
      }
      .arrow-text{
        letter-spacing: 2px;
        font-size: 20px;
        line-height: 28px;
        padding-left: 2px;
        color: #48497A;
      }
    }
    .arrow-words-en{
      width: 260px;
    }
  }
  .arrow-new-wrap{
    .arrow-new-box{
      width: 314px;
      height: 154px;
      position: relative;
      margin: 0 auto;
    }
    .arrow-new-box-en{
      height: 187px;
    }
    .arrow-new-box-jp{
      height: 187px;
    }
    .arrow-new-end{
      width: 64px;
      height: auto;
      position: absolute;
      top: 0;
      right: 0;
    }
    .arrow-new-head{
      width: 56px;
      height: auto;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .arrow-new-words{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-52%, -60%);
      width: 200px;
      text-align: center;
    }
    .arrow-new-words-jp{
      transform: translate(-50%,-48%);
      width: 100%;
    }
    .arrow-new-words-en{
      transform: translate(-50%,-48%);
      width: 100%;
    }
    .arrow-new-title{
      letter-spacing: 2px;
      font-size: 29px;
      line-height: 41px;
      padding-left: 2px;
      color: #48497A;
      margin-bottom: 5px;
    }
    .arrow-new-title-en{
      letter-spacing: 0;
      padding-left: 0;
    }
    .arrow-new-text{
      letter-spacing: 2px;
      font-size: 20px;
      line-height: 28px;
      padding-left: 2px;
      color: #48497A;
    }
    .arrow-new-text-en{
      letter-spacing: 0;
      padding-left: 0;
    }
    .arrow-new-video{
      cursor: pointer;
      position: absolute;
      bottom: -23px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      color: #5E61D0;
      width: 100%;
    }
    .arrow-new-video-jp{
      transform: translateX(-50%);
      width: 50%;
      bottom: -42px;
      line-height: 20px;
    }
    .arrow-new-video-icon{
      width: 15px;
      height: auto;
      vertical-align: middle;
      margin-bottom: 2px;
      margin-right: 6px;
    }
  }
  .angel-wrap{
    display: flex;
    justify-content: center;
    .angel-img-box{
      position: relative;
    }
    .angel-img{
      width: 355px;
      height: auto;
    }
    .angel-man{
      position: absolute;
      left: 58px;
      bottom: 40px;
    }
    .angel-lady{
      position: absolute;
      right: 58px;
      bottom: 40px;
      // background-color: rgba(0,0,0,0.2);
      // border-radius: 50%;
    }
    .angel-man-img,.angel-lady-img{
      width: 92px;
      height: auto;
      cursor: pointer;
    }
    .angel-man-mask,.angel-lady-mask{
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      z-index: 2;
      width: 92px;
      height: 92px;
      top: 0;
      left: 0;
      border-radius: 50%;
    }
    .angel-gender{
      font-size: 12px;
      line-height: 17px;
      font-weight: bold;
      color: #50528C;
      position: absolute;
      left: 50%;
      bottom: 9px;
      transform: translateX(-50%);
    }
    .angel-gender-en{
      bottom: 14px;
    }
    .tip-wrap{
      position: absolute;
      bottom: -38px;
      right: 8px;
      .tip-text2,.tip-text1{
        position: relative;
        text-align: right;
        font-size: 15px;
        line-height: 21px;
        color: #50528C;
      }
      .tip-line{
        position: relative;
        display: inline-block;
      }
      .tip-line::before{
        content: '';
        position: absolute;
        top: 56%;
        left: -86px;
        border-radius: 1px;
        transform: translateY(-50%);
        line-height: 21px;
        height: 1px;
        width: 82px;
        background-color: #50528C;
      }
    }
  }
  .area-code-box{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 7;
    .area-code-box-top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      .delect{
        width: 24px;
        height: auto;
        cursor: pointer;
      }
      .title{
        font-size: 17px;
        line-height: 24px;
        color: #48497A;
      }
      .sure-button{
        font-size: 17px;
        line-height: 24px;
        width: 24px;
        color: #906ADF;
        background-color: transparent;
        cursor: pointer;
        // margin: 0;
        padding: 0 2px 0 0;
      }
    }
    .country-list{
      flex-grow: 1;
      overflow-y: scroll;
      margin: 20px 0;
      .list-li{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        line-height: 22px;
        color: #706C95;
        padding: 17px 0px;
        margin: 0 30px;
        // border-top: 1px solid #D8D8E1;
        border-bottom: 1px solid rgba(216, 216, 225, 0.3);
        box-sizing: border-box;
      }
      .list-li-active{
        color: #906ADF;
      }
      .list-li-zh{
        position: relative;
      }
      .check{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -30px;
        width: 24px;
        height: auto;

      }
    }
  }
  .video-box{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    // width: 100vw;
    // height: 100vh;
    background-color: rgba(0,0,0,1);
    // display: flex;
    // align-items: center;
    z-index: 5;
    .video-src{
      width: 100%;
      object-fit: scale-down;
      // object-fit: contain;
      height: 100%;
    }
    .video-delect{
      position: absolute;
      top: 32px;
      right: 10px;
      width: 24px;
      height: auto;
      cursor: pointer;
    }
  }
  .banner-wrap{
    margin-top: 30px;
    text-align: center;
    .banner-img{
      width: 344px;
      height: auto;
      cursor: pointer;
    }
    
    }
  
  .language-drawer{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    z-index: 5;
    overflow: hidden;
    transform: translate3d(-100%, 0, 0);
    .box{
      position: absolute;
      top: 0;
      left: 0;
      width: 70%;
      background: rgba(255, 255, 255, 0.95);
      height: 100%;
    }
    .close-silde-box{
      margin-top: 16px;
      margin-left: 16px;
      width: 28px;
      height: 28px;
    }
    .global-wrap{
      margin: 26px 0 22px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .Twitter-wrap{
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .Twitter,.global{
      width: 28px;
      height: 28px;
      padding-left: 16px;
      padding-right: 16px;
    }
    .box-text{
      font-size: 16px;
      font-weight: 500;
      color: #48497A;
      line-height: 22px;
    }
  }
  .dlg-animate{
    transition: transform 0.3s ease-in-out;
  }
  .language-drawer-active{
    transform: translate3d(0, 0, 0);
  }
}
</style>