<template>
  <!-- 语言选择 -->
  <transition enter-active-class="animate__animated animate__faster animate__fadeInLeft" leave-active-class="animate__animated animate__faster animate__fadeOutLeft">
    <div v-show="drawer" :class="['area-code-box']">
    <!-- <div v-show="drawer" :class="['area-code-box','animate__animated','animate__faster',drawer ? 'animate__fadeInUpBig':'animate__fadeOutLeft']"> -->
      <div class="">
        <div class="area-code-box-top">
          <img @click="closeLanguageShow" class="delect" src="@/assets/img/matchmaker/delect@2x.png" alt="">
          <h2 class="title">{{$t("matchmaker_text5")}}</h2>
          <button class="sure-button"></button>
        </div>
      </div>
      <div class="country-list">
        <ul class="list-ul">
          <li @click="selectLanguage(item.value,item.label)" :class="['list-li',languageValue === item.value ? 'list-li-active':'']" v-for="item in languageOption" :key="item.value">
            <span class="list-li-zh">{{item.label}}<img v-if="languageValue === item.value" class="check" src="@/assets/img/matchmaker/check@2x.png" alt=""></span>
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
import { MATCHMAKER_LANGUAGE } from '@/utils/defaultValue'
import { localSet, localGet } from '@/utils/index'
import { selectEN,selectJP,selectHK } from '@/utils/gtagFun'
export default {
  data() {
    return {
      drawer:false,
      languageValue:'en',
      languageLocale:'English',
    }
  },
  mounted(){
    // this.languageValue = localGet(MATCHMAKER_LANGUAGE)
    // this.languageLocale = this.getLanguageLocale(this.languageValue)[0].label || '繁體中文'
  },
  computed:{
    languageOption:function(){
      return [
        // {value:'zhHK',label:'繁體中文'},
        {value:'en',label:'English'},
        // {value:'jp',label:'日本語'}
      ]
    },
  },
  methods:{
    closeLanguageShow(){
      this.drawer = false
    },
    selectLanguage(value,label){
      this.$i18n.locale = value
      this.languageValue = value
      this.languageLocale = label
      localSet(MATCHMAKER_LANGUAGE,value)
      this.closeLanguageShow()
      if(value == 'zhHK'){
        selectHK()
      }else if(value == 'en'){
        selectEN()
      }else if(value == 'jp'){
        selectJP()
      }
    },
    getLanguageLocale(value){
      let data = this.languageOption.filter(function(item){
        return item.value === value; 
      })
      let item =  data || [{value:'zhHK',label:'繁體中文'}]
      return item
    },
  }
}
</script>

<style lang="scss" scoped>
.area-code-box{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 7;
  .area-code-box-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    .delect{
      width: 24px;
      height: auto;
      cursor: pointer;
    }
    .title{
      font-size: 17px;
      line-height: 24px;
      color: #48497A;
    }
    .sure-button{
      font-size: 17px;
      line-height: 24px;
      width: 24px;
      color: #906ADF;
      background-color: transparent;
      cursor: pointer;
      // margin: 0;
      padding: 0 2px 0 0;
    }
  }
  .country-list{
    flex-grow: 1;
    overflow-y: scroll;
    margin: 20px 0;
    .list-li{
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      line-height: 22px;
      color: #706C95;
      padding: 17px 0px;
      margin: 0 30px;
      // border-top: 1px solid #D8D8E1;
      border-bottom: 1px solid rgba(216, 216, 225, 0.3);
      box-sizing: border-box;
    }
    .list-li-active{
      color: #906ADF;
    }
    .list-li-zh{
      position: relative;
    }
    .check{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -30px;
      width: 24px;
      height: auto;

    }
  }
}
</style>

