<template>
  <div class="interactive-agree-success">
    <div class="interactive-arrow-heart-wrap"><img class="interactive-arrow-heart" src="@/assets/img/matchmaker/interactive_agree_success@2x.png" alt=""></div>
    <div class="sure-text">
      {{$t("interactive_agree_success_title")}}
    </div>
    <div class="sure-text-tip">
      {{$t("interactive_agree_success_text")}}
    </div>
    <div class="sure-button-wrap">
      <div class="sure-div">
        <div @click="goHome" class="wrap">
          <span class="wrap-text">{{$t("interactive_agree_success_button")}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    goHome(){
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.interactive-agree-success{
  position: relative;
  z-index: 5;
  padding-bottom: 18px;
  .interactive-arrow-heart-wrap{
    // margin-top: 56px;
    text-align: center;
    .interactive-arrow-heart{
      width: 220px;
      height: auto;
    }
  }
  .sure-button-wrap{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .sure-text{
    font-size: 22px;
    font-weight: 400;
    color: #1B065E;
    line-height: 30px;
    text-align: center;
    max-width: 232px;
    margin: 25px auto 13px auto;
  }
  .sure-text-tip{
    font-size: 18px;
    font-weight: 400;
    // color: #48497A;
    color: rgba(72, 73, 122, 0.6);
    line-height: 25px;
    text-align: center;
    max-width: 232px;
    margin: 0px auto 42px auto;
    // opacity: 0.6;
  }
  .sure-button{
    width: 232px;
    height: 54px;
    background: #FCF9FB;
    border-radius: 36px;
    font-size: 18px;
    font-weight: 400;
    color: #1B065E;
    line-height: 25px;
    padding: 0 30px;
  }
  .sure-div{
    width: 232px;
    background: #FCF9FB;
    border-radius: 36px;
    box-sizing: border-box;
  }
  .wrap{
    position: relative;
    padding: 13px 0px 13px 0px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .wrap-text{
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #1B065E;
    position: relative;
    line-height: 28px;
    display: block;
    max-width: 126px;
  }
  .sure-icon{
    width: 28px;
    height: 28px;
  }
  .sure-button-margin-bottom{
    margin-bottom: 40px;
  }
  .sure-icon-margin-right{
    margin-right: 8px;
  }
}
</style>