<template>
  <div class="matter">
    <div class="matter-box">
      <div class="content">
        <!-- 箭头 -->
        <!-- <div class="arrow-wrap">
          <div class="arrow-img-box">
            <img class="arrow-img" src="../assets/img/matchmaker/arrow_matter@2x.png" alt="">
            <div class="arrow-words">
              <p :class="['arrow-text',{'arrow-text-en':$i18n.locale == 'en'}]">
                {{ i18nGenderShow }}
              </p>
            </div>
          </div>
        </div> -->
        <!-- 箭头 -->
        <div class="arrow-new-wrap">
          <div class="arrow-new-img-box">
            <img class="arrow-new-head-img" src="../assets/img/matchmaker/arrow_head_matter@2x.png" alt="">
            <img class="arrow-new-end-img" src="../assets/img/matchmaker/arrow_end_matter@2x.png" alt="">
            <div :class="['arrow-new-words',{'arrow-new-words-en':$i18n.locale == 'en'},{'arrow-new-words-jp':$i18n.locale == 'jp'}]">
              <p :class="['arrow-new-text',{'arrow-new-text-en':$i18n.locale == 'en'}]">
                {{ i18nGenderShow }}
              </p>
            </div>
          </div>
        </div>
        <div class="minddle-wrap">
          <div :class="['minddle-title',{'minddle-title-en':$i18n.locale == 'en'}]">{{$t("matter_text2")}}</div>
          <div class="minddle-button-wrap button-wrap-margin1">
            <button class="minddle-button1" v-preventReClick @click="goDeliverySuccess">{{i18nGenderButtonShow}}</button>
          </div>
          <div class="minddle-button-wrap">
            <button class="minddle-button1" v-preventReClick @click="getUserPhone">{{$t("matter_text4")}}</button>
          </div>
          <div class="minddle-button-wrap button-wrap-margin2">
            <button
                v-clipboard:copy="copyLinkValue"
                v-clipboard:success="CopySuccess"
                v-clipboard:error="CopyError" 
              class="minddle-button2">
              <img class="copy-share-img" src="../assets/img/matchmaker/share@2x.png" alt="">{{$t("matter_text5")}}
            </button>
          </div>
          <div class="delect-account-wrap">
            <button v-preventReClick @click="delectAccount" class="delect-account-button">{{$t("deliverySuccess_text10_delect_account")}}</button>
          </div>
        </div>
        <!-- 官网 -->
        <!-- <div class="banner-wrap">
          <img v-if="$i18n.locale == 'en'" @click="goToPeekWeb"  class="banner-img" src="../assets/img/matchmaker/banner_en@2x.png" alt="">
          <img v-else-if="$i18n.locale == 'jp'" @click="goToPeekWeb"  class="banner-img" src="../assets/img/matchmaker/banner_jp@2x.png" alt="">
          <img v-else @click="goToPeekWeb"  class="banner-img" src="../assets/img/matchmaker/banner@2x.png" alt="">
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { localGet,localDelect,countDownDelctTimer } from '@/utils/index'
import { MATCHMAKER_GENDER,MATCHMAKER_PHONE,MATCHMAKER_AREA,MATCHMAKER_SESSION_ID } from '@/utils/defaultValue'
import { adClickNum1,adClickNum4,clickShareNum } from '@/utils/gtagFun'
import commonData from '@/mixin/index.js'
import { 
  getPhoneInfGql
} from "@/graphql/matchmaker"
export default {
  mixins: [commonData],
  data() {
    return {
      debounceCopy:null,
      delete_time:0,// 匹配成功，互相删除时间
      delete_timer:null,
      // delectTimeText:'00小時00分鐘00秒'
      delectTimeText:'0'
    }
  },
  mounted(){
    this.delectTimeText = `00${this.$t("common_hour")}00${this.$t("common_minute")}00${this.$t("common_second")}`
    if(!localGet(MATCHMAKER_SESSION_ID)){
      this.$router.push('/')
    }
  },
  computed:{
    // 判断是男女，1是男
    genderShow:function(){
      return localGet(MATCHMAKER_GENDER) === 1 ? true : false
    },
    // 判断是男女
    i18nGenderShow:function(){
      return localGet(MATCHMAKER_GENDER) === 1 ? this.$t("matter_text1_exchanged_with_lady") : this.$t("matter_text1_exchanged_with_gentleman")
    },
    // 判断是按钮的他，区分男女
    i18nGenderButtonShow:function(){
      return localGet(MATCHMAKER_GENDER) === 1 ? this.$t("matter_text3_lady") : this.$t("matter_text3_gentleman")
    },
    copyLinkValue:function(){
      // return `網絡姻緣一線牽，珍惜這段緣。丘比特辦事處分發對象了 ${window.location.protocol}//${window.location.host}/#/?data=1`
      
      // return `${this.$t("deliverySuccess_text8")} ${window.location.protocol}//${window.location.host}/#/?data=1`
      return `${this.$t("deliverySuccess_text8")} https://www.cupidoffice.com/#/?data=1`
    },
  },
  methods:{
    goDeliverySuccess(){
      this.$router.push({ name: 'deliverySuccess'})
    },
    getUserPhone(){
      if(localGet(MATCHMAKER_SESSION_ID)){
        this.getUserInf()
      }else{
        this.$router.push('/')
      }
    },
    getUserInf(){
      const query = {
        session_id:localGet(MATCHMAKER_SESSION_ID),
        get_verify_code:false,
        gender:parseInt(localGet(MATCHMAKER_GENDER)),
        phone_country_code:parseInt(localGet(MATCHMAKER_AREA)),
        phone:localGet(MATCHMAKER_PHONE),
        lag:this.i18nTypeNum,
        is_delete_phone:false
      }
      this.$apollo.mutate({
        mutation: getPhoneInfGql,
        context: {
          headers: {
            'x-api-key': this.$HeaderKey
          }
        },
        variables: query,
        update: (store, { data: { GameOfYueLaoDeliveryContact } }) => {
          if(GameOfYueLaoDeliveryContact.err_code === 0){
            this.handleData(GameOfYueLaoDeliveryContact)
          }else{
            this.$message({
              message: this.codeFail(GameOfYueLaoDeliveryContact.err_code),
              type: 'error',
              center: true,
              customClass:'copy-error-message',
            });
          }
        }
      }).catch((error) => {
        this.$message({
          message: this.tipFail.unknownReason,
          type: 'error',
          center: true,
          customClass:'copy-error-message',
        });
      })
    },
    // 处理数据
    handleData(value){
      if(value.result_type === 3){
        this.$router.push({ name: 'deliverySuccess'})
      }else if(value.result_type === 1||value.result_type === 2){
        this.delectTimeText =  this.$t("matter_text6",{ time: countDownDelctTimer(value.delete_match_remain_second*1000) })
        this.$message({
          message: this.delectTimeText,
          type: 'success',
          center: true,
          customClass:'copy-success-message',
        });
        // this.delete_timer = setInterval(this.delectTimeDown, 1000)
      }else{
        localDelect(MATCHMAKER_GENDER)
        localDelect(MATCHMAKER_PHONE)
        localDelect(MATCHMAKER_AREA)
        localDelect(MATCHMAKER_SESSION_ID)
        this.$router.push('/')
      }
    },
    // 删除倒计时
    // delectTimeDown(){
    //   this.delete_time --
    //   this.delectTimeText= countDownDelctTimer(this.delete_time*1000) + '後再來吧'
    // },
    // 复制成功触发
    CopySuccess(){
      if(this.debounceCopy){
        clearTimeout(this.debounceCopy)
      }
      this.debounceCopy = setTimeout(() => {
        console.log('复制成功')
        clickShareNum()
        this.$message({
          message: this.$t("common_copy_success_send"),
          type: 'success',
          center: true,
          customClass:'copy-success-message',
        });
      }, 500)
    },
    // 复制失败触发
    CopyError() {
      if(this.debounceCopy){
        clearTimeout(this.debounceCopy)
      }
      this.debounceCopy = setTimeout(() => {
        console.log('复制失败')
        this.$message({
          message: this.$t("common_copy_fail"),
          type: 'error',
          center: true,
          customClass:'copy-error-message',
        });
      }, 500)
    },
    // 删除账号
    delectAccount(){
      this.$message({
        message: this.$t("deliverySuccess_text10_delect_account_tip2"),
        type:"success",
        center: true,
        customClass:'copy-success-message',
        duration:6000
      });
    },
    // 去官网
    goToPeekWeb(){
      adClickNum1()
      adClickNum4()
      window.open('https://peek.com.hk/')
    },
  }
}
</script>
<style lang="scss">
.matter{
  width: 100%;
  height: 100%;
  max-height: 900px;
  // display: flex;
  // justify-content: center;
  .matter-box{
    margin: 0 auto;
    position: relative;
    background: linear-gradient(180deg, #E0D8F7 0%, #ECBACC 100%);
    max-width: 450px;
    min-width: 360px;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content{
    margin: 20px 0;
  }
  .arrow-wrap{
    display: flex;
    justify-content: center;
    .arrow-img-box{
      position: relative;
      margin-left: 20px;
    }
    .arrow-img{
      width: 290px;
      height: auto;
    }
    .arrow-words{
      position: absolute;
      top: 54%;
      left: 46%;
      transform: translate(-50%,-50%);
      width: 274px;
      text-align: center;
      .arrow-text{
        letter-spacing: 2px;
        font-size: 28px;
        line-height: 40px;
        padding-left: 2px;
        color: #48497A;
      }
      .arrow-text-en{
        letter-spacing: 0;
        padding-left: 0;
      }
    }
  }
  .arrow-new-wrap{
    .arrow-new-img-box{
      width: 286px;
      height: 164px;
      position: relative;
      margin: 0 auto;
    }
    .arrow-new-head-img{
      position: absolute;
      width: 67px;
      height: auto;
      bottom: 0;
      left: 0;
    }
    .arrow-new-end-img{
      position: absolute;
      width: 64px;
      height: auto;
      top: 0;
      right: 0;
    }
    .arrow-new-words{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-52%,-40%);
      width: 100%;
      text-align: center;
    }
    .arrow-new-words-en{
      width: 350px;
      transform: translate(-50%,-47%);
    }
    .arrow-new-words-jp{
      transform: translate(-50%,-44%);
    }
    .arrow-new-text{
      letter-spacing: 2px;
      font-size: 28px;
      line-height: 40px;
      padding-left: 2px;
      color: #48497A;
    }
    .arrow-new-text-en{
      letter-spacing: 0;
      padding-left: 0;
    }
  }
  .minddle-wrap{
    .minddle-title{
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      color: #48497A;
      letter-spacing: 2px;
      padding-left: 2px;
      margin-top: 10px;
    }
    .minddle-title-en{
      letter-spacing: 0;
      padding-left: 0;
    }
    .minddle-button-wrap{
      text-align: center;
    }
    .minddle-button1{
      width: 256px;
      height: 50px;
      border-radius: 26px;
      font-size: 15px;
      line-height: 21px;
      color: #48497A;
      border: 1px solid #48497A;
      background-color: rgba(255, 255, 255, 0.2);
    }
    .minddle-button2{
      width: 256px;
      height: 50px;
      border-radius: 26px;
      font-size: 15px;
      line-height: 21px;
      color: #48497A;
      background-color: rgba(255, 255, 255, 0.25);
    }
    .copy-share-img{
      width: 16px;
      height: 16px;
      // padding-bottom: 2px;
      margin-right: 10px;
      vertical-align: middle;
    }
    .button-wrap-margin1{
      margin: 39px 0 28px 0;
    }
    .button-wrap-margin2{
      margin-top: 24px;
    }
    .delect-account-wrap{
      margin-top: 21px;
      text-align: center;
      .delect-account-button{
        background: transparent;
        font-size: 13px;
        line-height: 18px;
        color: rgba(112, 108, 149, 1);
        cursor: pointer;
      }
    }
  }
  .banner-wrap{
    margin-top: 28px;
    text-align: center;
    .banner-img{
      width: 344px;
      height: auto;
      cursor: pointer;
    }
  }
}
</style>