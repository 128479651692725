<template>
  <div class="interactive-not-sure">
    <div class="interactive-arrow-heart-wrap"><img class="interactive-arrow-heart" src="@/assets/img/matchmaker/interactive_agree_fail.png" alt=""></div>
    <div class="sure-text">
      {{$t("interactive_not_agree_title")}}
    </div>
    <div class="go-home-box">
      <div @click="goHome" class="go-home-bg-wrap">
        <img class="go-home-bg-left" src="@/assets/img/matchmaker/interactive_go_home_bg_left@2x.png" alt="">
        <img class="go-home-bg-right" src="@/assets/img/matchmaker/interactive_go_home_bg_right@2x.png" alt="">
        <img class="go-home-bg-people" src="@/assets/img/matchmaker/interactive_go_home_people@2x.png" alt="">
        <div class="go-home-bg-text">{{$t("interactive_fail_bg_agree_text")}}</div>
        <div :class="['go-home-bg-title',{'go-home-bg-title-jp':$i18n.locale === 'jp'}]">{{$t("interactive_fail_bg_title")}}<img class="go-home-bg-back" src="@/assets/img/matchmaker/interactive_go_back_home@2x.png" alt=""></div>
        <img  class="go-home-bg" src="@/assets/img/matchmaker/interactive_go_home_bg@2x.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    goHome(){
      this.$router.push('/')
    },
    // clickGoAgree(){
    //   this.$emit('handleCut','InteractiveAgree')
    // },
  }
}
</script>

<style lang="scss" scoped>
.interactive-not-sure{
  position: relative;
  z-index: 5;
  // padding-bottom: 26px;
  .interactive-arrow-heart-wrap{
    // margin-top: 56px;
    text-align: center;
    .interactive-arrow-heart{
      width: 220px;
      height: auto;
    }
  }
  .sure-text{
    font-size: 22px;
    font-weight: 400;
    color: #1B065E;
    line-height: 30px;
    text-align: center;
    max-width: 232px;
    margin: 25px auto 63px auto;
  }
  .sure-button-margin-bottom{
    margin-bottom: 40px;
  }
  .sure-icon-margin-right{
    margin-right: 8px;
  }
  .go-home-box{
    .go-home-bg-wrap{
      position: relative;
      cursor: pointer;
    }
    .go-home-bg-left{
      position: absolute;
      width: 94px;
      height: auto;
      bottom: -5px;
      left: -7px;
    }
    .go-home-bg-right{
      position: absolute;
      width: 93px;
      height: auto;
      top: -7px;
      right: -7px;
    }
    .go-home-bg-people{
      position: absolute;
      width: 97px;
      height: auto;
      top: -31px;
      left: 16px;
    }
    .go-home-bg-text{
      position: absolute;
      width: 242px;
      height: auto;
      display: block;
      top: 56px;
      left: 17px;
      font-size: 12px;
      font-weight: 500;
      color: #1B065E;
      line-height: 17px;
    }
    
    .go-home-bg-title{
      position: absolute;
      height: auto;
      display: block;
      top: 19px;
      left: 132px;
      font-size: 14px;
      font-weight: bold;
      color: #1B065E;
      line-height: 20px;
    }
    .go-home-bg-title-jp{
      left: 114px;
    }
    .go-home-bg-back{
      position: absolute;
      width: 14px;
      height: auto;
      top: 50%;
      transform: translateY(-50%);
      right:-18px;
    }
    .go-home-bg{
      width: 277px;
      height: auto;
    }
  }
}
</style>