<template>
  <div class="deliverySuccess">
    <div class="deliverySuccess-box">
      <div v-show="enterStatus" class="content">
        <!-- 箭头 -->
        <div class="arrow-heart-wrap">
          <img class="arrow-heart-img" src="../assets/img/matchmaker/arrow_heart@2x.png" alt="">
        </div>
        <div class="minddle-wrap">
          <h2 v-if="numShow" :class="['minddle-title',{'minddle-title-en':$i18n.locale == 'en'}]">{{$t("deliverySuccess_title")}}</h2>
          <h2 v-else :class="['minddle-title',{'minddle-title-en':$i18n.locale == 'en'}]">{{$t("deliverySuccess_matched_title")}}</h2>
          
          <!-- 不用排队显示的文案 -->
          <div v-if="numShow">
            <div class="minddle-text1">
              <!-- <p class="minddle-text1-p">將於{{delectTimeText}}後刪除雙方在“丘比特辦事處”的手機號碼</p> -->
              <p :class="['minddle-text1-p',{'minddle-text1-p-en':$i18n.locale == 'en'}]">{{ $t('deliverySuccess_text1', { delectTime:delectTimeText }) }}</p>
            </div>
            <div class="middle-phone">
              <div :class="['middle-phone-box',{'middle-phone-box-en':$i18n.locale == 'en'}]">
                <!-- <p class="phone-box-text1">我們已將您的手機號碼推送給一位{{genderShow?'女士':'男士'}}</p>
                <p class="phone-box-text2">對方的手機號碼為：</p> -->
                <!-- <p class="phone-box-text1">{{ $t('deliverySuccess_text2', { gender:i18nGenderIsLadyShow }) }}</p> -->
                <p class="phone-box-text1">{{i18nSendToGender}}</p>
                <p class="phone-box-text2">{{i18nisNumberGender}}</p>
                <!-- <p class="phone-box-text3">+852 13189880909</p> -->
                <p class="phone-box-text3"> <a class="phone-box-text3-a" :href="'tel:' +'+'+ peer_phone_country_code+''+peer_phone">+{{peer_phone_country_code}} {{peer_phone}}</a></p>
                <img class="left-bottom-stroke" src="../assets/img/matchmaker/stroke@2x.png" alt="">
                <img class="right-top-stroke" src="../assets/img/matchmaker/stroke@2x.png" alt="">
              </div>
            </div>
            <div class="copy-phone-wrap">
              <button
                v-clipboard:copy="copyPhoneValue"
                v-clipboard:success="CopyPhoneSuccess"
                v-clipboard:error="CopyPhoneError"
                :class="['copy-phone-button',{'copy-phone-button-jp':$i18n.locale == 'jp'}]"
              >{{$t("deliverySuccess_text9")}}</button>
            </div>
          </div>
          <!-- 排队显示的文案 -->
          <div v-else>
            <!-- <div class="middle-line-up-box">
              <div class="line-up-content">
                <div class="line-up1">
                  <img class="user-img" src="../assets/img/matchmaker/user@2x.png" alt="">
                  {{ $t('deliverySuccess_text4', { waitNumber:wait_queue_number,gender:i18nGenderIsManShow }) }}
                </div>
                <div class="line-up2">
                  <img class="queuing-time-img" src="../assets/img/matchmaker/queuing_time@2x.png" alt="">
                  {{$t("deliverySuccess_text5")}}{{queueTimeText}}
                </div>
              </div>
            </div>
            <div class="invitation-button-wrap">
              <button
                v-clipboard:copy="copyLinkValue"
                v-clipboard:success="CopySuccess"
                v-clipboard:error="CopyError"
                :class="['invitation-button',{'invitation-button-en':$i18n.locale == 'en'}]"
              >{{$t("deliverySuccess_text6")}}</button>
            </div> -->
            <div class="middle-not-phone-text-wrap">
              <p class="not-phone-box-text1">{{ $t('deliverySuccess_text7', { gender:i18nGenderIsLadyShow }) }}</p>
            </div>
            <div class="middle-not-phone">
              <div class="middle-not-phone-box">
                <!-- <p class="not-phone-box-text1">當前沒有可交換的手機號碼，我們將會在收到{{genderShow?'女士':'男士'}}遞交手機號碼後，立即通過訊息通知您，並在24小時後刪除雙方在“丘比特辦事處”的手機號碼。</p> -->
                <!-- <p class="not-phone-box-text1">{{ $t('deliverySuccess_text7', { gender:i18nGenderIsLadyShow }) }}</p> -->
                <div class="middle-line-up-box">
                  <div class="line-up-content">
                    <div class="line-up1">
                      <img class="user-img" src="../assets/img/matchmaker/user@2x.png" alt="">
                      {{ $t('deliverySuccess_text4', { waitNumber:wait_queue_number,gender:i18nGenderIsManShow }) }}
                    </div>
                    <div class="line-up2">
                      <img class="queuing-time-img" src="../assets/img/matchmaker/queuing_time@2x.png" alt="">
                      {{$t("deliverySuccess_text5")}}{{queueTimeText}}
                    </div>
                  </div>
                </div>
                <div class="invitation-button-wrap">
                  <button
                    v-clipboard:copy="copyLinkValue"
                    v-clipboard:success="CopySuccess"
                    v-clipboard:error="CopyError"
                    :class="['invitation-button',{'invitation-button-en':$i18n.locale == 'en'}]"
                  >{{$t("deliverySuccess_text6")}}</button>
                </div>
                <div class="delect-account-wrap">
                  <button @click="clickDelectBox" class="delect-account-button">{{$t("deliverySuccess_text10_delect_account")}}</button>
                </div>
                <img class="left-top-stroke" src="../assets/img/matchmaker/stroke_two@2x.png" alt="">
              </div>
            </div>
          </div>
        </div>
        
        <!-- 官网 -->
        <!-- <div class="banner-wrap">
          <img v-if="$i18n.locale == 'en'" @click="goToPeekWeb"  class="banner-img" src="../assets/img/matchmaker/banner_en@2x.png" alt="">
          <img v-else-if="$i18n.locale == 'jp'" @click="goToPeekWeb"  class="banner-img" src="../assets/img/matchmaker/banner_jp@2x.png" alt="">
          <img v-else @click="goToPeekWeb"  class="banner-img" src="../assets/img/matchmaker/banner@2x.png" alt="">
        </div> -->
      </div>
      <transition name="el-fade-in">
        <div v-if="delectShow" :class="['delect-box']">
          <div class="delect-box-content">
            <div class="delect-box-text">{{$t("deliverySuccess_delect_text")}}</div>
            <div class="delect-button-wrap">
              <button @click="closeDelect" class="delect-button delect-button-margin-right">{{$t("deliverySuccess_delect_cancel_button")}}</button>
              <button :disabled="deleteStatus" @click="delectAccount" class="delect-button">{{$t("deliverySuccess_delect_sure_button")}}</button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { localGet,localDelect,countDownTimer,countDownDelctTimer } from '@/utils/index'
import { MATCHMAKER_GENDER,MATCHMAKER_PHONE,MATCHMAKER_AREA,MATCHMAKER_SESSION_ID } from '@/utils/defaultValue'
import { adClickNum1,adClickNum3,clickShareNum,shareWaitNum,sharePairPhoneSuccessNum } from '@/utils/gtagFun'
import commonData from '@/mixin/index.js'
import { 
  getPhoneInfGql
} from "@/graphql/matchmaker"
export default {
  mixins: [commonData],
  data() {
    return {
      enterStatus:false,//进来的状态
      numShow:true,
      debounceCopy:null,
      debouncePhoneCopy:null,
      peer_phone:0, // 对方手机号码
      peer_phone_country_code:0,// 对方手机区号
      enter_queue_time:0,// 排队时间
      wait_queue_number:0,// 排队人数
      match_time:0,// 匹配成功的时间
      delete_time:0,// 匹配成功，互相删除时间
      data:null,
      queue_timer:null,
      delete_timer:null,
      // queueTimeText:'00天00小時00分鐘00秒',
      // delectTimeText:'00小時00分鐘00秒'
      queueTimeText:'0',
      delectTimeText:'0',
      deleteStatus:false, // 防止点击过快
      delectShow:false, // 删除弹窗
    }
  },
  mounted(){
    // this.handleData(this.$route.query)
    // this.data = JSON.parse(this.$route.query)

    // this.enterStatus = true
    // this.numShow = false
    this.queueTimeText = `0${this.$t("common_second")}`
    this.delectTimeText = `0${this.$t("common_second")}`
    setTimeout(()=>{this.enterStatus = true},6000)
    if(localGet(MATCHMAKER_SESSION_ID)){
      this.getUserInf()
    }else{
      this.$router.push('/')
    }
  },
  destroyed(){
    clearInterval(this.queue_timer)
    clearInterval(this.delete_timer)
    this.queue_timer = null
    this.delete_timer = null
  },
  computed:{
    copyPhoneValue:function(){
      return `+${this.peer_phone_country_code} ${this.peer_phone}`
    },
    copyLinkValue:function(){
      // return `${this.$t("deliverySuccess_text8")} ${window.location.protocol}//${window.location.host}/#/?data=1`
      return `${this.$t("deliverySuccess_text8")} https://www.cupidoffice.com/#/?data=1`
    },
    // 判断是男女，1是男
    genderShow:function(){
      return localGet(MATCHMAKER_GENDER) === 1 ? true : false
    },
    // 发送给男或者女
    i18nSendToGender:function(){
      return localGet(MATCHMAKER_GENDER) === 1 ? this.$t("deliverySuccess_text2_send_to_lady") : this.$t("deliverySuccess_text2_send_to_gentleman")
    },
    // 对方的手机号码是
    i18nisNumberGender:function(){
      return localGet(MATCHMAKER_GENDER) === 1 ? this.$t("deliverySuccess_text3_her_number_is") : this.$t("deliverySuccess_text3_his_number_is")
    },
    // 判断是男女，女
    i18nGenderIsLadyShow:function(){
      return localGet(MATCHMAKER_GENDER) === 1 ? this.$t("common_lady_lowercase") : this.$t("common_man_lowercase")
    },
    // 判断是男女，男
    i18nGenderIsManShow:function(){
      if(parseInt(this.wait_queue_number)>= 2){
        return localGet(MATCHMAKER_GENDER) === 1 ? this.$t("common_man_plural") : this.$t("common_lady_plural")
      }else{
        return localGet(MATCHMAKER_GENDER) === 1 ? this.$t("common_man") : this.$t("common_lady")
      }
      
    },
    // 是否要排队
    
  },
  methods:{
    getUserInf(){
      const query = {
        session_id:localGet(MATCHMAKER_SESSION_ID),
        get_verify_code:false,
        gender:parseInt(localGet(MATCHMAKER_GENDER)),
        phone_country_code:parseInt(localGet(MATCHMAKER_AREA)),
        phone:localGet(MATCHMAKER_PHONE),
        lag:this.i18nTypeNum,
        is_delete_phone:false
      }
      this.$apollo.mutate({
        mutation: getPhoneInfGql,
        context: {
          headers: {
            'x-api-key': this.$HeaderKey
          }
        },
        variables: query,
        update: (store, { data: { GameOfYueLaoDeliveryContact } }) => {
          setTimeout(()=>{
            this.enterStatus = true
          },500)
          if(GameOfYueLaoDeliveryContact.err_code === 0){
            this.handleData(GameOfYueLaoDeliveryContact)
          }else{
            this.$message({
              message: this.codeFail(GameOfYueLaoDeliveryContact.err_code),
              center: true,
              type: 'error',
              customClass:'copy-error-message',
            });
          }
        }
      }).catch((error) => {
        this.$message({
          message: this.tipFail.unknownReason,
          center: true,
          type: 'error',
          customClass:'copy-error-message',
        });
      })
    },
    handleData(value){
      if(value.result_type === 4){
        localDelect(MATCHMAKER_GENDER)
        localDelect(MATCHMAKER_PHONE)
        localDelect(MATCHMAKER_AREA)
        localDelect(MATCHMAKER_SESSION_ID)
        this.$router.push('/')
      }
      this.numShow = parseInt( value.result_type) === 3 ? false : true
      this.peer_phone = value.peer_phone || 0
      this.peer_phone_country_code = value.peer_phone_country_code || 0
      this.enter_queue_time = value.enter_queue_time || 0
      this.wait_queue_number = value.wait_queue_number || 0
      this.delete_time = value.delete_match_remain_second || 0
      
      if(!this.numShow){
        this.queueTimeDown()
        this.queue_timer = setInterval(this.queueTimeDown, 1000)
        setTimeout(()=>{
          this.getUserInf()
        },5000)
      }else{
        this.delete_timer = setInterval(this.delectTimeDown, 1000)
      }
    },
    // 排队倒计时
    queueTimeDown(){
      let time = new Date().getTime() * 1000 - parseInt(this.enter_queue_time)
      this.queueTimeText=countDownTimer(time/1000)
    },
    // 删除倒计时
    delectTimeDown(){
      if(this.delete_time <= 0){
        clearInterval(this.delete_timer)
        this.delete_timer = null
        this.delete_time = 0
        this.delectTimeText=countDownDelctTimer(this.delete_time*1000)
        localDelect(MATCHMAKER_GENDER)
        localDelect(MATCHMAKER_PHONE)
        localDelect(MATCHMAKER_AREA)
        localDelect(MATCHMAKER_SESSION_ID)
        this.$router.push('/')
        return
      }else{
        this.delete_time --
        this.delectTimeText=countDownDelctTimer(this.delete_time*1000)
      }
    },
    // 复制成功触发
    CopySuccess(){
      if(this.debounceCopy){
        clearTimeout(this.debounceCopy)
      }
      this.debounceCopy = setTimeout(() => {
        console.log('复制成功')
        clickShareNum()
        shareWaitNum()
        this.$message({
          message: this.$t("common_copy_success_send"),
          center: true,
          type: 'success',
          customClass:'copy-success-message',
        });
      }, 500)
    },
    // 复制失败触发
    CopyError() {
      if(this.debounceCopy){
        clearTimeout(this.debounceCopy)
      }
      this.debounceCopy = setTimeout(() => {
        console.log('复制失败')
        this.$message({
          message: this.$t("common_copy_fail"),
          center: true,
          type: 'error',
          customClass:'copy-error-message',
        });
      }, 500)
    },
    // 复制手机号码成功触发
    CopyPhoneSuccess(){
      if(this.debouncePhoneCopy){
        clearTimeout(this.debouncePhoneCopy)
      }
      this.debouncePhoneCopy = setTimeout(() => {
        console.log('复制成功')
        this.$message({
          message: this.$t("common_copy_success"),
          type: 'success',
          center: true,
          customClass:'copy-success-message',
        });
        sharePairPhoneSuccessNum()
      }, 500)
    },
    // 复制手机号码失败触发
    CopyPhoneError() {
      if(this.debouncePhoneCopy){
        clearTimeout(this.debouncePhoneCopy)
      }
      this.debouncePhoneCopy = setTimeout(() => {
        console.log('复制失败')
        this.$message({
          message: this.$t("common_copy_fail"),
          type: 'error',
          center: true,
          customClass:'copy-error-message',
        });
      }, 500)
    },
    // 打开删除弹窗
    clickDelectBox(){
      this.delectShow = true
    },
    // 关闭删除弹窗
    closeDelect(){
      this.delectShow = false
    },
    // 删除账号
    delectAccount(){
      this.deleteStatus = true
      this.delectShow = false
      setTimeout(()=>{
        this.deleteStatus = false
      },5000)
      const query = {
        get_verify_code:false,
        gender:parseInt(localGet(MATCHMAKER_GENDER)),
        phone_country_code:parseInt(localGet(MATCHMAKER_AREA)),
        phone:localGet(MATCHMAKER_PHONE),
        lag:this.i18nTypeNum,
        is_delete_phone:true
      }
      this.$apollo.mutate({
        mutation: getPhoneInfGql,
        context: {
          headers: {
            'x-api-key': this.$HeaderKey
          }
        },
        variables: query,
        update: (store, { data: { GameOfYueLaoDeliveryContact } }) => {
          if(GameOfYueLaoDeliveryContact.err_code === 0){
            localDelect(MATCHMAKER_GENDER)
            localDelect(MATCHMAKER_PHONE)
            localDelect(MATCHMAKER_AREA)
            localDelect(MATCHMAKER_SESSION_ID)
            this.$message({
              message: this.$t("deliverySuccess_text10_delect_account_tip1"),
              type: 'success',
              center: true,
              customClass:'copy-success-message',
            });
            setTimeout(() => {
              this.$router.push('/')
            }, 3000);
          }else{
            this.$message({
              message: this.codeFail(GameOfYueLaoDeliveryContact.err_code),
              center: true,
              type: 'error',
              customClass:'copy-error-message',
            });
            if(GameOfYueLaoDeliveryContact.err_code === -80008){
              this.getUserInf()
            }
            this.deleteStatus = false
          }
        }
      }).catch((error) => {
        this.$message({
          message: this.tipFail.unknownReason,
          center: true,
          type: 'error',
          customClass:'copy-error-message',
        });
      })
    },
    // 去官网
    goToPeekWeb(){
      adClickNum1()
      adClickNum3()
      window.open('https://peek.com.hk/')
      
    },
  }
}
</script>
<style lang="scss">
.deliverySuccess{
  width: 100%;
  height: 100%;
  max-height: 900px;
  // display: flex;
  // justify-content: center;
  .deliverySuccess-box{
    margin: 0 auto;
    position: relative;
    background: linear-gradient(180deg, #E0D8F7 0%, #ECBACC 100%);
    max-width: 450px;
    min-width: 360px;
    width: 100%;
    // min-height: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content{
    margin: 20px 0;
  }
  .arrow-heart-wrap{
    text-align: center;
    .arrow-heart-img{
      width: 131px;
      height: auto;
    }
   
  }
  .minddle-wrap{
    margin-top: 23px;
    .minddle-title{
      text-align: center;
      font-size: 26px;
      line-height: 37px;
      letter-spacing: 1px;
      color: #48497A;
    }
    .minddle-title-en{
      letter-spacing: 0;
    }
    .minddle-text1{
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: 17px;
      .minddle-text1-p{
        width: 286px;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 1px;
        color: #48497A;
      }
      .minddle-text1-p-en{
        letter-spacing: 0;
      }
    }
    .middle-phone{
      display: flex;
      justify-content: center;
      margin-top: 35px;
      .middle-phone-box{
        position: relative;
        width: 200px;
        // border-top:1px solid rgba(80, 82, 140, 1);
        // border-bottom:1px solid rgba(80, 82, 140, 1);
        padding: 28px 0;
      }
      .middle-phone-box-en{
        width: 220px;
      }
      .middle-phone-box::before{
        content: '';
        position: absolute;
        width: 94%;
        height: 1px;
        background-color: rgba(80, 82, 140, 1);
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      .middle-phone-box::after{
        content: '';
        position: absolute;
        width: 94%;
        height: 1px;
        background-color: rgba(80, 82, 140, 1);
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      .phone-box-text1,.phone-box-text2{
        text-align: center;
        font-size: 16px;
        line-height: 21px;
        color: #48497A;
      }
      .phone-box-text2{
        margin-top: 2px;
      }
      .phone-box-text3{
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        color: #7779C7;
        margin-top: 12px;
      }
      .phone-box-text3-a{
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        color: #7779C7;
      }
      .right-top-stroke,.left-bottom-stroke{
        position: absolute;
        width: 19px;
        height: auto;
      }
      .right-top-stroke{
        right: -11px;
        top: -11px;
      }
      .left-bottom-stroke{
        left: -11px;
        bottom: -11px;
      }
    }
    .copy-phone-wrap{
      margin-top: 30px;
      text-align: center;
      .copy-phone-button{
        width: 184px;
        height: 46px;
        background: #6567AE;
        border-radius: 25px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        // padding-left: 1px;
        color: #FFFFFF;
        cursor: pointer;
      }
      .copy-phone-button-jp{
        width: 210px;
      }
    }
    .middle-line-up-box{
      // display: flex;
      // justify-content: center;
      position: relative;
      height: 56px;
      .line-up-content{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
      .line-up1,.line-up2{
        font-size: 15px;
        line-height: 21px;
        color: #7071B1;
        font-weight: 500;
        white-space:nowrap;
      }
      .line-up2{
        margin-top: 13px;
      }
      .user-img,.queuing-time-img{
        width: 16px;
        height: auto;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    .invitation-button-wrap{
      margin-top: 23px;
      text-align: center;
      .invitation-button{
        width: 184px;
        height: 46px;
        background: #6567AE;
        border-radius: 25px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        // padding-right: 1px;
        color: #FFFFFF;
        cursor: pointer;
      }
      .invitation-button-en{
        width: 216px;
      }
    }
    .delect-account-wrap{
      margin-top: 21px;
      text-align: center;
      .delect-account-button{
        background: transparent;
        font-size: 13px;
        line-height: 18px;
        color: rgba(112, 108, 149, 1);
        cursor: pointer;
      }
    }
    .middle-not-phone-text-wrap{
      margin-top: 11px;
      .not-phone-box-text1{
        text-align: center;
        font-size: 15px;
        line-height: 22px;
        color: #48497A;
        width: 291px;
        margin: 0 auto;
      }
    }
    .middle-not-phone{
      display: flex;
      justify-content: center;
      margin-top: 35px;
      .middle-not-phone-box{
        position: relative;
        width: 236px;
        padding: 25px 0 0 0;
      }
      .middle-not-phone-box::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: rgba(80, 82, 140, 1);
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      // .middle-not-phone-box::after{
      //   content: '';
      //   position: absolute;
      //   width: 94%;
      //   height: 1px;
      //   background-color: rgba(80, 82, 140, 1);
      //   bottom: 0;
      //   left: 50%;
      //   transform: translateX(-50%);
      // }
      
      .left-top-stroke{
        position: absolute;
        width: 36px;
        height: auto;
        top: -19px;
        left: -36px;
      }
    }
  }
  .delect-box{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 7;
    .delect-box-content{
      width: 65%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
      background: #FFFFFF;
      border-radius: 16px;
      padding: 35px;
    }
    .delect-box-text{
      font-size: 16px;
      font-weight: 500;
      color: #48497A;
      line-height: 25px;
    }
    .delect-button-wrap{
      margin-top: 26px;
      .delect-button{
        width: 100px;
        height: 36px;
        font-weight: 500;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 20px;
        border: 1px solid #48497A;
        font-size: 15px;
        color: #48497A;
        cursor: pointer;
      }
      .delect-button-margin-right{
        margin-right: 25px;
      }
    }
  }
  .banner-wrap{
    margin-top: 20px;
    text-align: center;
    .banner-img{
      width: 344px;
      height: auto;
      cursor: pointer;
    }
  }
}
</style>