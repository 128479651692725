<template>
  <div class="interactive-sure">
    <div class="interactive-arrow-heart-wrap"><img class="interactive-arrow-heart" src="@/assets/img/matchmaker/interactive_arrow_heart@2x.png" alt=""></div>
    <div class="sure-text">
      {{$t("interactive_sure_title")}}
    </div>
    <div class="sure-button-wrap">
      <div class="sure-div sure-button-margin-bottom">
        <div @click="clickSure" class="wrap">
          <img class="sure-icon sure-icon-margin-right" src="@/assets/img/matchmaker/interactive_sure@2x.png" alt="">
          <span class="wrap-text">{{$t("interactive_sure_button")}}</span>
        </div>
      </div>
      <div class="sure-div">
        <div @click="clickNotSure" class="wrap">
          <img class="sure-icon sure-icon-margin-right" src="@/assets/img/matchmaker/interactive_not_sure@2x.png" alt="">
          <span :class="['wrap-text',{'wrap-text-jp':$i18n.locale === 'jp'}]">{{$t("interactive_sure_not_button")}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods:{
    clickSure(){
      this.$emit('handleCut','InteractiveSingle')
    },
    clickNotSure(){
      this.$emit('handleCut','InteractiveNotSure')
    }
  }
}
</script>

<style lang="scss" scoped>
.interactive-sure{
  position: relative;
  z-index: 5;
  // padding-bottom: 26px;
  .interactive-arrow-heart-wrap{
    // margin-top: 56px;
    text-align: center;
    .interactive-arrow-heart{
      width: 60px;
      height: auto;
    }
  }
  .sure-button-wrap{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .sure-text{
    // margin-top: 22px;
    // margin-bottom: 55px;
    font-size: 22px;
    font-weight: 400;
    color: #1B065E;
    line-height: 25px;
    text-align: center;
    max-width: 232px;
    margin: 22px auto 55px auto;
  }
  .sure-button{
    width: 232px;
    height: 54px;
    background: #FCF9FB;
    border-radius: 36px;
    font-size: 18px;
    font-weight: 400;
    color: #1B065E;
    line-height: 25px;
    padding: 0 30px;
  }
  .sure-div{
    width: 232px;
    background: #FCF9FB;
    border-radius: 36px;
    box-sizing: border-box;
  }
  .wrap{
    position: relative;
    padding: 13px 0px 13px 0px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .wrap-text{
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #1B065E;
    position: relative;
    line-height: 25px;
    display: block;
    max-width: 126px;
    padding: 1px 0;
  }
  .wrap-text-jp{
    max-width: 162px;
  }
  .sure-icon{
    width: 28px;
    height: 28px;
  }
  .sure-button-margin-bottom{
    margin-bottom: 40px;
  }
  .sure-icon-margin-right{
    margin-right: 8px;
  }
}
</style>