import { localGet } from '@/utils/index'
import {MATCHMAKER_LANGUAGE } from '@/utils/defaultValue'
export default {
  computed:{
    // 语言数字
    i18nTypeNum:function(){
      let langNum = 1
      if(localGet(MATCHMAKER_LANGUAGE) === 'zhHK'){
        langNum = 1
      }else if(localGet(MATCHMAKER_LANGUAGE) === 'en'){
        langNum = 2
      }else if(localGet(MATCHMAKER_LANGUAGE) === 'zhTW'){
        langNum = 3
      }else if(localGet(MATCHMAKER_LANGUAGE) === 'zhCN'){
        langNum = 4
      }else if(localGet(MATCHMAKER_LANGUAGE) === 'jp'){
        langNum = 5
      }else{
        langNum = 1
      }
      return  langNum
    },
    tipFail:function(){
      return {
        serverError:this.$t('codoFail1'),
        unknownReason: this.$t('codoFail1'),
        code_10005:this.$t('codoFail2'),
        code_80001:this.$t('codoFail3'),
        code_80002:this.$t('codoFail4'),
        code_80003:this.$t('codoFail5'),
        code_80004:this.$t('codoFail6'),
        code_80005:this.$t('codoFail6'),
        code_80006:this.$t('codoFail1'),
        code_80007:this.$t('codoFail1'),
        code_80008:this.$t('deliverySuccess_text10_delect_account_tip2'),
        code_10016:this.$t('codoFail10016'),
      }
    }
  },
  methods:{
    codeFail(code) {
      switch (code) {
        case -10000:
          return this.tipFail.unknownReason
        case -10001:
          return this.tipFail.unknownReason
        case -10005:
          return this.tipFail.code_10005
        case -10016:
          return this.tipFail.code_10016  
        case -80001:
          return this.tipFail.code_80001
        case -80002:
          return this.tipFail.code_80002
        case -80003:
          return this.tipFail.code_80003
        case -80004:
          return this.tipFail.code_80004
        case -80005:
          return this.tipFail.code_80005
        case -80008:
            return this.tipFail.code_80008  
        default:
          return this.tipFail.unknownReason
      }
    }
  }
}