import gql from "graphql-tag"

/**
 * 
 * 获取验证码
 * 
 */
const getPhoneCodeGql = gql `
  mutation GameOfYueLaoDeliveryContact($phone_country_code: Int,$phone: String!,$gender: Int!,$get_verify_code: Boolean!,$lag: Int,$is_delete_phone: Boolean) {
    GameOfYueLaoDeliveryContact(input:{phone_country_code:$phone_country_code,phone:$phone,gender:$gender,get_verify_code:$get_verify_code,lag:$lag,is_delete_phone:$is_delete_phone}){
      result_type
      gender
      verify_code_id
      verify_code_try_remain_time
      err_code
      err_msg
    }
  }
`
/**
 * 投递
 * result_type：
 * 1，投递，对象匹配成功，将会返回对方的联系方式，并且通知对方。显示双方账号删除的剩余时间。
 * 2，投递，对象之前匹配过了，已经分配了，仍然会返回对方的联系方式，但不会通知对方，因为已经通知过了。显示双方账号删除的剩余时间。
 * 3，投递，但没有合适的对象，进入投递队列并显示排队时长
 * 
 * enter_queue_time：没有合适对象，进入投递队列的时间戳，单位：微秒，秒的百万分之一。结果类型为3时返回
 * wait_queue_number:没有合适对象，排在自己前面的人数，如果超过99人，将会返回99。结果类型为3时返回
 * 
 * peer_phone：匹配成功时，分配的对方联系方式结果类型为1，2时返回
 * match_time：匹配成功时的时间戳。结果类型为1，2时返回
 * delete_match_remain_second：分配对象成功后，还剩多久删除双方账号，单位：秒。结果类型为1，2时返回
 */
const submitPhoneGql = gql `
  mutation GameOfYueLaoDeliveryContact($phone_country_code: Int,$phone: String!,$gender: Int!,$get_verify_code: Boolean!,$verify_code_id: String,$verify_code: String,$lag: Int,$is_delete_phone: Boolean,$local_time_zone: Float) {
    GameOfYueLaoDeliveryContact(input:{phone_country_code:$phone_country_code,phone:$phone,gender:$gender,get_verify_code:$get_verify_code,verify_code_id:$verify_code_id,verify_code:$verify_code,lag:$lag,is_delete_phone:$is_delete_phone,local_time_zone:$local_time_zone}){
      result_type
      gender
      enter_queue_time
      wait_queue_second
      wait_queue_number
      peer_phone
      peer_phone_country_code
      match_time
      delete_match_remain_second
      session_id
      err_code
      err_msg
    }
  }
`
const getPhoneInfGql = gql `
  mutation GameOfYueLaoDeliveryContact($phone_country_code: Int,$phone: String!,$gender: Int!,$session_id: String,$get_verify_code: Boolean!,$lag: Int,$is_delete_phone: Boolean) {
    GameOfYueLaoDeliveryContact(input:{phone_country_code:$phone_country_code,phone:$phone,gender:$gender,session_id:$session_id,get_verify_code:$get_verify_code,lag:$lag,is_delete_phone:$is_delete_phone}){
      result_type
      gender
      enter_queue_time
      wait_queue_second
      wait_queue_number
      peer_phone
      peer_phone_country_code
      match_time
      delete_match_remain_second
      session_id
      err_code
      err_msg
    }
  }
`
const getIpGql = gql `
  query  QueryIPLocation($ip: String) {
    QueryIPLocation(input:{ip:$ip}){
      err_code
      err_msg
      country_code
      ip
      response
      trace_id
    }
  }
`
export { getPhoneCodeGql, submitPhoneGql,getPhoneInfGql,getIpGql };