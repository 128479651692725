const country = [
  {
    code: 'IM',
    name: 'Isle of Man',
    zh: '馬恩島',
	  jp: 'マン島',
    dial_code: '44',
  },
  {
    code: 'HR',
    name: 'Croatia',
    zh: '克羅地亞',
	  jp: 'クロアチア',
    dial_code: '385',
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
    zh: '幾內亞比紹',
	  jp: 'ギニアビサウ',
    dial_code: '245',
  },
  {
    code: 'IN',
    name: 'India',
    zh: '印度',
	  jp: 'インド',
    dial_code: '91',
  },
  {
    code: 'KE',
    name: 'Kenya',
    zh: '肯尼亞',
	  jp: 'ケニア',
    dial_code: '254',
  },
  {
    code: 'LA',
    name: 'Laos',
    zh: '老撾',
	  jp: 'ラオス',
    dial_code: '856',
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    zh: '英屬印度洋領地',
	  jp: '英領インド洋地域',
    dial_code: '246',
  },
  {
    code: 'HT',
    name: 'Haiti',
    zh: '海地',
	  jp: 'ハイチ',
    dial_code: '509',
  },
  {
    code: 'LB',
    name: 'Lebanon',
    zh: '黎巴嫩',
	  jp: 'レバノン',
    dial_code: '961',
  },
  {
    code: 'GY',
    name: 'Guyana',
    zh: '圭亞那',
	  jp: 'ガイアナ',
    dial_code: '592',
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
    zh: '吉爾吉斯斯坦',
	  jp: 'キルギスタン',
    dial_code: '996',
  },
  {
    code: 'HU',
    name: 'Hungary',
    zh: '匈牙利',
	  jp: 'ハンガリー',
    dial_code: '36',
  },
  {
    code: 'LC',
    name: 'St. Lucia',
    zh: '聖盧西亞',
	  jp: 'セントルシア',
    dial_code: '1',
  },
  {
    code: 'IQ',
    name: 'Iraq',
    zh: '伊拉克',
	  jp: 'イラク',
    dial_code: '964',
  },
  {
    code: 'KH',
    name: 'Cambodia',
    zh: '柬埔寨',
	  jp: 'カンボジア',
    dial_code: '855',
  },
  {
    code: 'JM',
    name: 'Jamaica',
    zh: '牙買加',
	jp: 'ジャマイカ',
    dial_code: '1',
  },
  {
    code: 'IR',
    name: 'Iran',
    zh: '伊朗',
	  jp: 'イラン',
    dial_code: '98',
  },
  {
    code: 'KI',
    name: 'Kiribati',
    zh: '基裏巴斯',
	  jp: 'キリバス',
    dial_code: '686',
  },
  {
    code: 'IS',
    name: 'Iceland',
    zh: '冰島',
	  jp: 'アイスランド',
    dial_code: '354',
  },
  {
    code: 'MA',
    name: 'Morocco',
    zh: '摩洛哥',
	  jp: 'モロッコ',
    dial_code: '212',
  },
  {
    code: 'JO',
    name: 'Jordan',
    zh: '約旦',
	  jp: 'ジョーダン',
    dial_code: '962',
  },
  {
    code: 'IT',
    name: 'Italy',
    zh: '意大利',
	  jp: 'イタリア語',
    dial_code: '39',
  },
  {
    code: 'JP',
    name: 'Japan',
    zh: '日本',
	  jp: '日本',
    dial_code: '81',
  },
  {
    code: 'MC',
    name: 'Monaco',
    zh: '摩納哥',
	  jp: 'モナコ',
    dial_code: '377',
  },
  {
    code: 'KM',
    name: 'Comoros',
    zh: '科摩羅',
	  jp: 'コモロ',
    dial_code: '269',
  },
  {
    code: 'MD',
    name: 'Moldova',
    zh: '摩爾多瓦',
	  jp: 'モルドバ',
    dial_code: '373',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    zh: '列支敦士登',
	  jp: 'リヒテンシュタイン',
    dial_code: '423',
  },
  {
    code: 'KN',
    name: 'St. Kitts & Nevis',
    zh: '聖基茨和尼維斯',
	  jp: 'セントクリストファーネイビス',
    dial_code: '1',
  },
  {
    code: 'ME',
    name: 'Montenegro',
    zh: '黑山',
	  jp: 'モンテネグロ',
    dial_code: '382',
  },
  {
    code: 'NA',
    name: 'Namibia',
    zh: '納米比亞',
	  jp: 'ナミビア',
    dial_code: '264',
  },
  {
    code: 'MF',
    name: 'St. Martin',
    zh: '法屬聖馬丁',
  	jp: 'フランスのサンマルタン',
    dial_code: '590',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    zh: '斯裏蘭卡',
  	jp: 'スリランカ',
    dial_code: '94',
  },
  {
    code: 'KP',
    name: 'North Korea',
    zh: '朝鮮',
  	jp: '北朝鮮',
    dial_code: '850',
  },
  {
    code: 'MG',
    name: 'Madagascar',
    zh: '馬達加斯加',
	  jp: 'マダガスカル',
    dial_code: '261',
  },
  {
    code: 'NC',
    name: 'New Caledonia',
    zh: '新喀裏多尼亞',
	  jp: 'ニューカレドニア',
    dial_code: '687',
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
    zh: '馬紹爾群島',
	  jp: 'マーシャル諸島',
    dial_code: '692',
  },
  {
    code: 'KR',
    name: 'South Korea',
    zh: '韓國',
	  jp: '韓国',
    dial_code: '82',
  },
  {
    code: 'NE',
    name: 'Niger',
    zh: '尼日爾',
	  jp: 'ニジェール',
    dial_code: '227',
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
    zh: '諾福克島',
	  jp: 'ノーフォーク島',
    dial_code: '672',
  },
  {
    code: 'MK',
    name: 'North Macedonia',
    zh: '北馬其頓',
	  jp: '北マケドニア',
    dial_code: '389',
  },
  {
    code: 'NG',
    name: 'Nigeria',
    zh: '尼日利亞',
	  jp: 'ナイジェリア',
    dial_code: '234',
  },
  {
    code: 'ML',
    name: 'Mali',
    zh: '馬裏',
	  jp: 'マリ',
    dial_code: '223',
  },
  {
    code: 'MM',
    name: 'Myanmar [Burma]',
    zh: '緬甸',
	  jp: 'ミャンマー',
    dial_code: '95',
  },
  {
    code: 'LR',
    name: 'Liberia',
    zh: '利比裏亞',
	  jp: 'リベリア',
    dial_code: '231',
  },
  {
    code: 'NI',
    name: 'Nicaragua',
    zh: '尼加拉瓜',
	  jp: 'ニカラグア',
    dial_code: '505',
  },
  {
    code: 'KW',
    name: 'Kuwait',
    zh: '科威特',
	  jp: 'クウェート',
    dial_code: '965',
  },
  {
    code: 'MN',
    name: 'Mongolia',
    zh: '蒙古',
    jp: 'モンゴル',
    dial_code: '976',
  },
  {
    code: 'LS',
    name: 'Lesotho',
    zh: '萊索托',
	  jp: 'レソト',
    dial_code: '266',
  },
  {
    code: 'PA',
    name: 'Panama',
    zh: '巴拿馬',
	  jp: 'パナマ',
    dial_code: '507',
  },
  {
    code: 'MO',
    name: 'Macao SAR',
    zh: '澳門',
	  jp: 'マカオ',
    dial_code: '853',
  },
  {
    code: 'LT',
    name: 'Lithuania',
    zh: '立陶宛',
	  jp: 'リトアニア',
    dial_code: '370',
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
    zh: '開曼群島',
	  jp: 'ケイマン諸島',
    dial_code: '1',
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
    zh: '北馬裏亞納群島',
	  jp: '北マリアナ諸島',
    dial_code: '1',
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    zh: '盧森堡',
	  jp: 'ルクセンブルグ',
    dial_code: '352',
  },
  {
    code: 'NL',
    name: 'Netherlands',
    zh: '荷蘭',
	  jp: 'オランダ',
    dial_code: '31',
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    zh: '哈薩克斯坦',
	  jp: 'カザフスタン',
    dial_code: '7',
  },
  {
    code: 'MQ',
    name: 'Martinique',
    zh: '馬提尼克',
	  jp: 'マルティニーク',
    dial_code: '596',
  },
  {
    code: 'LV',
    name: 'Latvia',
    zh: '拉脫維亞',
	  jp: 'ラトビア',
    dial_code: '371',
  },
  {
    code: 'MR',
    name: 'Mauritania',
    zh: '毛裏塔尼亞',
	  jp: 'モーリタニア',
    dial_code: '222',
  },
  {
    code: 'PE',
    name: 'Peru',
    zh: '秘魯',
	  jp: 'ペルー',
    dial_code: '51',
  },
  {
    code: 'MS',
    name: 'Montserrat',
    zh: '蒙特塞拉特',
	  jp: 'モンセラート',
    dial_code: '1',
  },
  {
    code: 'QA',
    name: 'Qatar',
    zh: '卡塔爾',
	  jp: 'カタール',
    dial_code: '974',
  },
  {
    code: 'NO',
    name: 'Norway',
    zh: '挪威',
	  jp: 'ノルウェー',
    dial_code: '47',
  },
  {
    code: 'PF',
    name: 'French Polynesia',
    zh: '法屬波利尼西亞',
	  jp: 'フランス領ポリネシア',
    dial_code: '689',
  },
  {
    code: 'MT',
    name: 'Malta',
    zh: '馬耳他',
	  jp: 'マルタ',
    dial_code: '356',
  },
  {
    code: 'LY',
    name: 'Libya',
    zh: '利比亞',
	  jp: 'リビア',
    dial_code: '218',
  },
  {
    code: 'NP',
    name: 'Nepal',
    zh: '尼泊爾',
	  jp: 'ネパール',
    dial_code: '977',
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
    zh: '巴佈亞新幾內亞',
	  jp: 'パプアニューギニア',
    dial_code: '675',
  },
  {
    code: 'MU',
    name: 'Mauritius',
    zh: '毛裏求斯',
	  jp: 'モーリシャス',
    dial_code: '230',
  },
  {
    code: 'PH',
    name: 'Philippines',
    zh: '菲律賓',
	  jp: 'フィリピン',
    dial_code: '63',
  },
  {
    code: 'MV',
    name: 'Maldives',
    zh: '馬爾代夫',
	  jp: 'モルディブ',
    dial_code: '960',
  },
  {
    code: 'OM',
    name: 'Oman',
    zh: '阿曼',
	  jp: 'オマーン',
    dial_code: '968',
  },
  {
    code: 'NR',
    name: 'Nauru',
    zh: '瑙魯',
	  jp: 'ナウル',
    dial_code: '674',
  },
  {
    code: 'MW',
    name: 'Malawi',
    zh: '馬拉維',
	  jp: 'マラウイ',
    dial_code: '265',
  },
  {
    code: 'MX',
    name: 'Mexico',
    zh: '墨西哥',
	  jp: 'メキシコ',
    dial_code: '52',
  },
  {
    code: 'PK',
    name: 'Pakistan',
    zh: '巴基斯坦',
	  jp: 'パキスタン',
    dial_code: '92',
  },
  {
    code: 'MY',
    name: 'Malaysia',
    zh: '馬來西亞',
	  jp: 'マレーシア',
    dial_code: '60',
  },
  {
    code: 'NU',
    name: 'Niue',
    zh: '紐埃',
  	jp: 'ニウエ',
    dial_code: '683',
  },
  {
    code: 'PL',
    name: 'Poland',
    zh: '波蘭',
	  jp: 'ポーランド',
    dial_code: '48',
  },
  {
    code: 'MZ',
    name: 'Mozambique',
    zh: '莫桑比克',
	  jp: 'モザンビーク',
    dial_code: '258',
  },
  {
    code: 'PM',
    name: 'St. Pierre & Miquelon',
    zh: '聖皮埃爾和密克隆群島',
	  jp: 'サンピエール島ミクロン島',
    dial_code: '508',
  },
  {
    code: 'RE',
    name: 'Réunion',
    zh: '留尼汪',
	  jp: '再会',
    dial_code: '262',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    zh: '沙特阿拉伯',
	  jp: 'サウジアラビア',
    dial_code: '966',
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
    zh: '所羅門群島',
	  jp: 'ソロモン諸島',
    dial_code: '677',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    zh: '新西蘭',
	  jp: 'ニュージーランド',
    dial_code: '64',
  },
  {
    code: 'SC',
    name: 'Seychelles',
    zh: '塞舌爾',
	  jp: 'セイシェル',
    dial_code: '248',
  },
  {
    code: 'SD',
    name: 'Sudan',
    zh: '蘇丹',
	  jp: 'スルタン',
    dial_code: '249',
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
    zh: '波多黎各',
	  jp: 'プエルトリコ',
    dial_code: '1',
  },
  {
    code: 'SE',
    name: 'Sweden',
    zh: '瑞典',
	  jp: 'スウェーデン',
    dial_code: '46',
  },
  {
    code: 'PS',
    name: 'Palestinian Territories',
    zh: '巴勒斯坦領土',
	  jp: 'パレスチナ自治区',
    dial_code: '970',
  },
  {
    code: 'TA',
    name: 'Tristan da Cunha',
    zh: '特裏斯坦-達庫尼亞群島',
	  jp: 'トリスタンダクーニャ',
    dial_code: '290',
  },
  {
    code: 'PT',
    name: 'Portugal',
    zh: '葡萄牙',
	  jp: 'ポルトガル語',
    dial_code: '351',
  },
  {
    code: 'SG',
    name: 'Singapore',
    zh: '新加坡',
	  jp: 'シンガポール',
    dial_code: '65',
  },
  {
    code: 'TC',
    name: 'Turks & Caicos Islands',
    zh: '特克斯和凱科斯群島',
	  jp: 'タークス・カイコス諸島',
    dial_code: '1',
  },
  {
    code: 'SH',
    name: 'St. Helena',
    zh: '聖赫勒拿',
	  jp: 'セントヘレナ',
    dial_code: '290',
  },
  {
    code: 'TD',
    name: 'Chad',
    zh: '乍得',
	  jp: 'チャド',
    dial_code: '235',
  },
  {
    code: 'SI',
    name: 'Slovenia',
    zh: '斯洛文尼亞',
	  jp: 'スロベニア',
    dial_code: '386',
  },
  {
    code: 'PW',
    name: 'Palau',
    zh: '帕勞',
	  jp: 'パラオ',
    dial_code: '680',
  },
  {
    code: 'SJ',
    name: 'Svalbard & Jan Mayen',
    zh: '斯瓦爾巴和揚馬延',
	  jp: 'スバールバル諸島およびヤンマイエン諸島',
    dial_code: '47',
  },
  {
    code: 'UA',
    name: 'Ukraine',
    zh: '烏克蘭',
	  jp: 'ウクライナ',
    dial_code: '380',
  },
  {
    code: 'RO',
    name: 'Romania',
    zh: '羅馬尼亞',
	  jp: 'ルーマニア',
    dial_code: '40',
  },
  {
    code: 'SK',
    name: 'Slovakia',
    zh: '斯洛伐克',
	  jp: 'スロバキア',
    dial_code: '421',
  },
  {
    code: 'PY',
    name: 'Paraguay',
    zh: '巴拉圭',
	  jp: 'パラグアイ',
    dial_code: '595',
  },
  {
    code: 'TG',
    name: 'Togo',
    zh: '多哥',
	  jp: 'トーゴ',
    dial_code: '228',
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
    zh: '塞拉利昂',
	  jp: 'シエラレオネ',
    dial_code: '232',
  },
  {
    code: 'TH',
    name: 'Thailand',
    zh: '泰國',
	  jp: 'タイ',
    dial_code: '66',
  },
  {
    code: 'SM',
    name: 'San Marino',
    zh: '聖馬力諾',
	  jp: 'サンマリノ',
    dial_code: '378',
  },
  {
    code: 'SN',
    name: 'Senegal',
    zh: '塞內加爾',
	  jp: 'セネガル',
    dial_code: '221',
  },
  {
    code: 'RS',
    name: 'Serbia',
    zh: '塞爾維亞',
	  jp: 'セルビア',
    dial_code: '381',
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    zh: '塔吉克斯坦',
	  jp: 'タジキスタン',
    dial_code: '992',
  },
  {
    code: 'VA',
    name: 'Vatican City',
    zh: '梵蒂岡',
	  jp: 'バチカン',
    dial_code: '39',
  },
  {
    code: 'SO',
    name: 'Somalia',
    zh: '索馬裏',
	  jp: 'ソマリア',
    dial_code: '252',
  },
  {
    code: 'TK',
    name: 'Tokelau',
    zh: '托克勞',
	  jp: 'トケラウ',
    dial_code: '690',
  },
  {
    code: 'UG',
    name: 'Uganda',
    zh: '烏幹達',
	  jp: 'ウガンダ',
    dial_code: '256',
  },
  {
    code: 'RU',
    name: 'Russia',
    zh: '俄羅斯',
	  jp: 'ロシア',
    dial_code: '7',
  },
  {
    code: 'TL',
    name: 'Timor-Leste',
    zh: '東帝汶',
	  jp: '東ティモール',
    dial_code: '670',
  },
  {
    code: 'VC',
    name: 'St. Vincent & Grenadines',
    zh: '聖文森特和格林納丁斯',
	  jp: 'セントビンセントおよびグレナディーン諸島',
    dial_code: '1',
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
    zh: '土庫曼斯坦',
	  jp: 'トルクメニスタン',
    dial_code: '993',
  },
  {
    code: 'SR',
    name: 'Suriname',
    zh: '蘇裏南',
	  jp: 'スリナム',
    dial_code: '597',
  },
  {
    code: 'RW',
    name: 'Rwanda',
    zh: '盧旺達',
	  jp: 'ルワンダ',
    dial_code: '250',
  },
  {
    code: 'TN',
    name: 'Tunisia',
    zh: '突尼斯',
	  jp: 'チュニジア',
    dial_code: '216',
  },
  {
    code: 'VE',
    name: 'Venezuela',
    zh: '委內瑞拉',
	jp: 'ベネズエラ',
    dial_code: '58',
  },
  {
    code: 'SS',
    name: 'South Sudan',
    zh: '南蘇丹',
	  jp: '南スーダン',
    dial_code: '211',
  },
  {
    code: 'TO',
    name: 'Tonga',
    zh: '湯加',
	  jp: 'トンガ',
    dial_code: '676',
  },
  {
    code: 'ST',
    name: 'São Tomé & Príncipe',
    zh: '聖多美和普林西比',
	  jp: 'サントメ・プリンシペ',
    dial_code: '239',
  },
  {
    code: 'VG',
    name: 'British Virgin Islands',
    zh: '英屬維爾京群島',
	  jp: '英領バージン諸島',
    dial_code: '1',
  },
  {
    code: 'SV',
    name: 'El Salvador',
    zh: '薩爾瓦多',
	  jp: 'エルサルバドル',
    dial_code: '503',
  },
  {
    code: 'TR',
    name: 'Turkey',
    zh: '土耳其',
	  jp: 'トルコ語',
    dial_code: '90',
  },
  {
    code: 'VI',
    name: 'U.S. Virgin Islands',
    zh: '美屬維爾京群島',
	  jp: '米領バージン諸島',
    dial_code: '1',
  },
  {
    code: 'SX',
    name: 'Sint Maarten',
    zh: '荷屬聖馬丁',
	  jp: 'シントマールテン',
    dial_code: '1',
  },
  {
    code: 'WF',
    name: 'Wallis & Futuna',
    zh: '瓦利斯和富圖納',
	  jp: 'ウォリス・フツナ',
    dial_code: '681',
  },
  {
    code: 'TT',
    name: 'Trinidad & Tobago',
    zh: '特立尼達和多巴哥',
    jp: 'トリニダード・トバゴ',
    dial_code: '1',
  },
  {
    code: 'SY',
    name: 'Syria',
    zh: '敘利亞',
	  jp: 'シリア',
    dial_code: '963',
  },
  {
    code: 'SZ',
    name: 'Eswatini',
    zh: '斯威士蘭',
	  jp: 'スワジランド',
    dial_code: '268',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
    zh: '圖瓦盧',
	  jp: 'ツバル',
    dial_code: '688',
  },
  {
    code: 'TW',
    name: 'Taiwan',
    zh: '臺灣',
	  jp: '台湾',
    dial_code: '886',
  },
  {
    code: 'VN',
    name: 'Vietnam',
    zh: '越南',
	  jp: 'ベトナム',
    dial_code: '84',
  },
  {
    code: 'US',
    name: 'United States',
    zh: '美國',
	  jp: 'アメリカ合衆国',
    dial_code: '1',
  },
  {
    code: 'TZ',
    name: 'Tanzania',
    zh: '坦桑尼亞',
	  jp: 'タンザニア',
    dial_code: '255',
  },
  {
    code: 'YE',
    name: 'Yemen',
    zh: '也門',
	  jp: 'イエメン',
    dial_code: '967',
  },
  {
    code: 'ZA',
    name: 'South Africa',
    zh: '南非',
	  jp: '南アフリカ',
    dial_code: '27',
  },
  {
    code: 'XK',
    name: 'Kosovo',
    zh: '科索沃',
	  jp: 'コソボ',
    dial_code: '383',
  },
  {
    code: 'UY',
    name: 'Uruguay',
    zh: '烏拉圭',
	  jp: 'ウルグアイ',
    dial_code: '598',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
    zh: '瓦努阿圖',
	  jp: 'バヌアツ',
    dial_code: '678',
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    zh: '烏茲別克斯坦',
	  jp: 'ウズベキスタン',
    dial_code: '998',
  },
  {
    code: 'WS',
    name: 'Samoa',
    zh: '薩摩亞',
	  jp: 'サモア',
    dial_code: '685',
  },
  {
    code: 'ZM',
    name: 'Zambia',
    zh: '贊比亞',
	  jp: 'ザンビア',
    dial_code: '260',
  },
  {
    code: 'AC',
    name: 'Ascension Island',
    zh: '阿森鬆島',
	  jp: 'アセンション島',
    dial_code: '247',
  },
  {
    code: 'AD',
    name: 'Andorra',
    zh: '安道爾',
	  jp: 'アンドラ',
    dial_code: '376',
  },
  {
    code: 'YT',
    name: 'Mayotte',
    zh: '馬約特',
	  jp: 'マヨット',
    dial_code: '262',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    zh: '阿拉伯聯合酋長國',
	  jp: 'アラブ首長国連邦',
    dial_code: '971',
  },
  {
    code: 'BA',
    name: 'Bosnia & Herzegovina',
    zh: '波斯尼亞和黑塞哥維那',
	  jp: 'ボスニア・ヘルツェゴビナ',
    dial_code: '387',
  },
  {
    code: 'AF',
    name: 'Afghanistan',
    zh: '阿富汗',
	  jp: 'アフガニスタン',
    dial_code: '93',
  },
  {
    code: 'BB',
    name: 'Barbados',
    zh: '巴巴多斯',
	  jp: 'バルバドス',
    dial_code: '1',
  },
  {
    code: 'AG',
    name: 'Antigua & Barbuda',
    zh: '安提瓜和巴佈達',
	  jp: 'アンティグアバーブーダ',
    dial_code: '1',
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    zh: '孟加拉國',
	  jp: 'バングラデシュ',
    dial_code: '880',
  },
  {
    code: 'AI',
    name: 'Anguilla',
    zh: '安圭拉',
	  jp: 'アンギラ',
    dial_code: '1',
  },
  {
    code: 'BE',
    name: 'Belgium',
    zh: '比利時',
	  jp: 'ベルギー',
    dial_code: '32',
  },
  {
    code: 'CA',
    name: 'Canada',
    zh: '加拿大',
	  jp: 'カナダ',
    dial_code: '1',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
    zh: '佈基納法索',
	  jp: 'ブルキナファソ',
    dial_code: '226',
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    zh: '保加利亞',
	  jp: 'ブルガリア',
    dial_code: '359',
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
    zh: '津巴佈韋',
	  jp: 'ジンバブエ',
    dial_code: '263',
  },
  {
    code: 'AL',
    name: 'Albania',
    zh: '阿爾巴尼亞',
	  jp: 'アルバニア',
    dial_code: '355',
  },
  {
    code: 'CC',
    name: 'Cocos [Keeling] Islands',
    zh: '科科斯［基林］群島',
	  jp: 'ココス[キーリング]諸島',
    dial_code: '61',
  },
  {
    code: 'BH',
    name: 'Bahrain',
    zh: '巴林',
	  jp: 'バーレーン',
    dial_code: '973',
  },
  {
    code: 'AM',
    name: 'Armenia',
    zh: '亞美尼亞',
	  jp: 'アルメニア',
    dial_code: '374',
  },
  {
    code: 'CD',
    name: 'Congo - Kinshasa',
    zh: '剛果［金］',
	  jp: 'コンゴ[ゴールデン]',
    dial_code: '243',
  },
  {
    code: 'BI',
    name: 'Burundi',
    zh: '佈隆迪',
	jp: 'ブルンジ',
    dial_code: '257',
  },
  {
    code: 'BJ',
    name: 'Benin',
    zh: '貝寧',
	jp: 'ベニン',
    dial_code: '229',
  },
  {
    code: 'AO',
    name: 'Angola',
    zh: '安哥拉',
	jp: 'アンゴラ',
    dial_code: '244',
  },
  {
    code: 'CF',
    name: 'Central African Republic',
    zh: '中非共和國',
	jp: '中央アフリカ共和国',
    dial_code: '236',
  },
  {
    code: 'CG',
    name: 'Congo - Brazzaville',
    zh: '剛果［佈］',
	jp: 'コンゴ[ブラザ]',
    dial_code: '242',
  },
  {
    code: 'BL',
    name: 'St. Barthélemy',
    zh: '聖巴泰勒米',
	jp: 'サンバルテルミー',
    dial_code: '590',
  },
  {
    code: 'CH',
    name: 'Switzerland',
    zh: '瑞士',
	jp: 'スイス',
    dial_code: '41',
  },
  {
    code: 'BM',
    name: 'Bermuda',
    zh: '百慕大',
	jp: 'バミューダ',
    dial_code: '1',
  },
  {
    code: 'AR',
    name: 'Argentina',
    zh: '阿根廷',
	jp: 'アルゼンチン',
    dial_code: '54',
  },
  {
    code: 'CI',
    name: 'Côte d’Ivoire',
    zh: '科特迪瓦',
	jp: 'コートジボワール',
    dial_code: '225',
  },
  {
    code: 'BN',
    name: 'Brunei',
    zh: '文萊',
	jp: 'ブルネイ',
    dial_code: '673',
  },
  {
    code: 'DE',
    name: 'Germany',
    zh: '德國',
	jp: 'ドイツ',
    dial_code: '49',
  },
  {
    code: 'AS',
    name: 'American Samoa',
    zh: '美屬薩摩亞',
	jp: 'アメリカ領サモア',
    dial_code: '1',
  },
  {
    code: 'BO',
    name: 'Bolivia',
    zh: '玻利維亞',
	jp: 'ボリビア',
    dial_code: '591',
  },
  {
    code: 'AT',
    name: 'Austria',
    zh: '奧地利',
	jp: 'オーストリア',
    dial_code: '43',
  },
  {
    code: 'CK',
    name: 'Cook Islands',
    zh: '庫克群島',
	jp: 'クック諸島',
    dial_code: '682',
  },
  {
    code: 'AU',
    name: 'Australia',
    zh: '澳大利亞',
	jp: 'オーストラリア',
    dial_code: '61',
  },
  {
    code: 'CL',
    name: 'Chile',
    zh: '智利',
	jp: 'チリ',
    dial_code: '56',
  },
  {
    code: 'EC',
    name: 'Ecuador',
    zh: '厄瓜多爾',
	jp: 'エクアドル',
    dial_code: '593',
  },
  {
    code: 'BQ',
    name: 'Caribbean Netherlands',
    zh: '荷屬加勒比區',
	jp: 'オランダカリブ海',
    dial_code: '599',
  },
  {
    code: 'CM',
    name: 'Cameroon',
    zh: '喀麥隆',
	jp: 'カメルーン',
    dial_code: '237',
  },
  {
    code: 'BR',
    name: 'Brazil',
    zh: '巴西',
	jp: 'ブラジル',
    dial_code: '55',
  },
  {
    code: 'AW',
    name: 'Aruba',
    zh: '阿魯巴',
	jp: 'アルバ',
    dial_code: '297',
  },
  {
    code: 'CN',
    name: 'China Mainland',
    zh: '中國大陸',
	jp: '中国本土',
    dial_code: '86',
  },
  {
    code: 'EE',
    name: 'Estonia',
    zh: '愛沙尼亞',
	jp: 'エストニア',
    dial_code: '372',
  },
  {
    code: 'BS',
    name: 'Bahamas',
    zh: '巴哈馬',
	jp: 'バハマ',
    dial_code: '1',
  },
  {
    code: 'DJ',
    name: 'Djibouti',
    zh: '吉佈提',
	jp: 'ジブチ',
    dial_code: '253',
  },
  {
    code: 'AX',
    name: 'Åland Islands',
    zh: '奧蘭群島',
	jp: 'オーランド諸島',
    dial_code: '358',
  },
  {
    code: 'CO',
    name: 'Colombia',
    zh: '哥倫比亞',
	jp: 'コロンビア',
    dial_code: '57',
  },
  {
    code: 'BT',
    name: 'Bhutan',
    zh: '不丹',
	jp: 'ブータン',
    dial_code: '975',
  },
  {
    code: 'DK',
    name: 'Denmark',
    zh: '丹麥',
	jp: 'デンマーク',
    dial_code: '45',
  },
  {
    code: 'EG',
    name: 'Egypt',
    zh: '埃及',
	jp: 'エジプト',
    dial_code: '20',
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
    zh: '阿塞拜疆',
	jp: 'アゼルバイジャン',
    dial_code: '994',
  },
  {
    code: 'EH',
    name: 'Western Sahara',
    zh: '西撒哈拉',
	jp: '西サハラ',
    dial_code: '212',
  },
  {
    code: 'DM',
    name: 'Dominica',
    zh: '多米尼克',
	jp: 'ドミニカ',
    dial_code: '1',
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    zh: '哥斯達黎加',
	jp: 'コスタリカ',
    dial_code: '506',
  },
  {
    code: 'BW',
    name: 'Botswana',
    zh: '博茨瓦納',
	jp: 'ボツワナ',
    dial_code: '267',
  },
  {
    code: 'GA',
    name: 'Gabon',
    zh: '加蓬',
	jp: 'ガボン',
    dial_code: '241',
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
    zh: '多米尼加共和國',
	jp: 'ドミニカ共和国',
    dial_code: '1',
  },
  {
    code: 'BY',
    name: 'Belarus',
    zh: '白俄羅斯',
	jp: 'ベラルーシ',
    dial_code: '375',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    zh: '英國',
	jp: 'イギリス',
    dial_code: '44',
  },
  {
    code: 'CU',
    name: 'Cuba',
    zh: '古巴',
	jp: 'キューバ',
    dial_code: '53',
  },
  {
    code: 'BZ',
    name: 'Belize',
    zh: '伯利茲',
	jp: 'ベリーズ',
    dial_code: '501',
  },
  {
    code: 'CV',
    name: 'Cape Verde',
    zh: '佛得角',
	jp: 'カーボベルデ',
    dial_code: '238',
  },
  {
    code: 'GD',
    name: 'Grenada',
    zh: '格林納達',
	jp: 'グレナダ',
    dial_code: '1',
  },
  {
    code: 'FI',
    name: 'Finland',
    zh: '芬蘭',
	jp: 'フィンランド',
    dial_code: '358',
  },
  {
    code: 'CW',
    name: 'Curaçao',
    zh: '庫拉索',
	jp: 'キュラソー',
    dial_code: '599',
  },
  {
    code: 'GE',
    name: 'Georgia',
    zh: '格魯吉亞',
	jp: 'ジョージア',
    dial_code: '995',
  },
  {
    code: 'FJ',
    name: 'Fiji',
    zh: '斐濟',
	jp: 'フィジー',
    dial_code: '679',
  },
  {
    code: 'CX',
    name: 'Christmas Island',
    zh: '聖誕島',
	jp: 'クリスマス島',
    dial_code: '61',
  },
  {
    code: 'GF',
    name: 'French Guiana',
    zh: '法屬圭亞那',
	jp: 'フランス領ギア',
    dial_code: '594',
  },
  {
    code: 'FK',
    name: 'Falkland Islands',
    zh: '福克蘭群島',
	jp: 'フォークランド諸島',
    dial_code: '500',
  },
  {
    code: 'CY',
    name: 'Cyprus',
    zh: '塞浦路斯',
	jp: 'キプロス',
    dial_code: '357',
  },
  {
    code: 'GG',
    name: 'Guernsey',
    zh: '根西島',
	jp: 'ガーンジー',
    dial_code: '44',
  },
  {
    code: 'CZ',
    name: 'Czechia',
    zh: '捷克',
	jp: 'チェコ語',
    dial_code: '420',
  },
  {
    code: 'GH',
    name: 'Ghana',
    zh: '加納',
	jp: 'ガーナ',
    dial_code: '233',
  },
  {
    code: 'FM',
    name: 'Micronesia',
    zh: '密克羅尼西亞',
	jp: 'ミクロネシア',
    dial_code: '691',
  },
  {
    code: 'ER',
    name: 'Eritrea',
    zh: '厄立特裏亞',
	jp: 'エリトリア',
    dial_code: '291',
  },
  {
    code: 'GI',
    name: 'Gibraltar',
    zh: '直佈羅陀',
	jp: 'ジブラルタル',
    dial_code: '350',
  },
  {
    code: 'ES',
    name: 'Spain',
    zh: '西班牙',
	jp: 'スペイン',
    dial_code: '34',
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
    zh: '法羅群島',
	jp: 'フェロー諸島',
    dial_code: '298',
  },
  {
    code: 'ET',
    name: 'Ethiopia',
    zh: '埃塞俄比亞',
	jp: 'エチオピア',
    dial_code: '251',
  },
  {
    code: 'GL',
    name: 'Greenland',
    zh: '格陵蘭',
	jp: 'グリーンランド',
    dial_code: '299',
  },
  {
    code: 'DZ',
    name: 'Algeria',
    zh: '阿爾及利亞',
	jp: 'アルジェリア',
    dial_code: '213',
  },
  {
    code: 'GM',
    name: 'Gambia',
    zh: '岡比亞',
	jp: 'ガンビア',
    dial_code: '220',
  },
  {
    code: 'ID',
    name: 'Indonesia',
    zh: '印度尼西亞',
	jp: 'インドネシア',
    dial_code: '62',
  },
  {
    code: 'FR',
    name: 'France',
    zh: '法國',
	jp: 'フランス',
    dial_code: '33',
  },
  {
    code: 'GN',
    name: 'Guinea',
    zh: '幾內亞',
	jp: 'ギニア',
    dial_code: '224',
  },
  {
    code: 'IE',
    name: 'Ireland',
    zh: '愛爾蘭',
	jp: 'アイルランド',
    dial_code: '353',
  },
  {
    code: 'HK',
    name: 'Hong Kong SAR',
    zh: '香港',
	jp: '香港',
    dial_code: '852',
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
    zh: '瓜德羅普',
	jp: 'グアドループ',
    dial_code: '590',
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
    zh: '赤道幾內亞',
	jp: '赤道ギニア',
    dial_code: '240',
  },
  {
    code: 'GR',
    name: 'Greece',
    zh: '希臘',
	jp: 'ギリシャ',
    dial_code: '30',
  },
  {
    code: 'HN',
    name: 'Honduras',
    zh: '洪都拉斯',
	jp: 'ホンジュラス',
    dial_code: '504',
  },
  {
    code: 'JE',
    name: 'Jersey',
    zh: '澤西島',
	jp: 'ジャージー',
    dial_code: '44',
  },
  {
    code: 'GT',
    name: 'Guatemala',
    zh: '危地馬拉',
	jp: 'グアテマラ',
    dial_code: '502',
  },
  {
    code: 'GU',
    name: 'Guam',
    zh: '關島',
	jp: 'グアム',
    dial_code: '1',
  },
  {
    code: 'IL',
    name: 'Israel',
    zh: '以色列',
	jp: 'イスラエル',
    dial_code: '972',
  },
]
export default country